import React, { useEffect, useRef, useState } from "react";
import AppStandardButton from "../../components/UI/AppButtons";
import BookingGetters from "../../store/onlineBooking/getters";
import { ReservationDetailsPreview } from "../../components/Layouts/ReservationDetailsPreview/ReservationDetailsPreview";
import { useTranslation } from "react-i18next";
import {
  AllPaymentMethodsResponse,
  NewPaymentsMethods,
} from "../../@types/DTO/AllPaymentMethods";
import { PayPal } from "../../components/Layouts/PaymentForms/PayPal";
import { createSearchParams, useNavigate } from "react-router-dom";
import BookingApi from "../../API/Apis/BookingApi";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { SelectedVehicleDetails } from "../../@types/Booking/SelectedVehicleDetails";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import PersonalDetails from "./PersonalDetails";
import NewPersonalDetails from "./NewPersonalDetails";
import CompanyDetails from "./CompanyDetails";
import NewCompanyDetails from "./NewCompanyDetails";
import TermsAndConditions from "./TermsAndConditions";
import AlertMessage from "../AlertMessage";
import i18n, { t } from "i18next";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import AppButton from "../../components/UI/AppButtons";
import { PrivacyPolicy } from "../../@types/DTO/privacyPolicy";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import { useDispatch } from "react-redux";

function PaymentDetailsPage({
  reservationSourcePayNow,
  reservationSourcePayLater,
  updateVehiclePrice,
  updateSelectedPrice,
  allPayNowMethods,
  allPayLaterMethods,
  updateActiveStep,
  isAirport,
}) {
  const { t: translate } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [invalidError, setInvalidError] = useState("");
  const [dateError, setDateError] = useState("");
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const updateUserPrimaryDetails = (key, value) => {
    if (key == "firstName") {
      setFirstName(value);
    } else if (key == "lastName") {
      setLastName(value);
    } else if (key == "email") {
      setEmail(value);
    } else if (key == "telephone") {
      setPhoneNo(value);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (firstName && lastName && email) {
      dispatch(
        BOOKING_ACTIONS.setCustomerPrimaryDetails({
          firstName,
          lastName,
          email,
          telephone: phoneNo,
        })
      );
    }
  }, [firstName, lastName, email, phoneNo]);
  const [companyinvoiceDetails, setCompanyinvoiceDetails] = useState<any>([]);
  const currentLoggedInuser: string | undefined =
    localStorage.getItem("userID") || undefined;
  const navigateTo = useNavigate();
  const lang = i18n.language;
  const payPalFormRef = useRef<HTMLFormElement>();
  const AppSettings = BookingGetters.getAppSettings();
  const [checked, setChecked] = React.useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [ReservationId, setReservationId] = useState<number>(0);
  const VehicleDetails = BookingGetters.getSelectedVehicleDetails();
  const [extrasSelected, setExtrasSelected] = useState<SelectedVehicleDetails>(
    []
  );
  useEffect(() => {
    setExtrasSelected(VehicleDetails.paymentDetails);
  }, []);
  const UserDetails = BookingGetters.getUserDetails();
  const [whetherIfGroupIsOnRequest, setWhetherIfGroupIsOnRequest] =
    useState<string>("false");
  useEffect(() => {
    if (VehicleDetails.vehicleDetails?.Status.toLowerCase() == "onrequest") {
      setWhetherIfGroupIsOnRequest("true");
    } else setWhetherIfGroupIsOnRequest("false");
  });
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  const [paypalBody, setPaypalBody] = useState<string | undefined>();
  const [stripeBody, setStripeBody] = useState<string | undefined>();
  const [nexiBody, setNexiBody] = useState<string | undefined>();
  const [bookingPaymentSubDescData, setBookingPaymentSubDescData] = useState<
    string | undefined
  >();
  useEffect(() => {
    const fetchTextResources = async () => {
      const [PayPalPayLabelObject, stripePayLabelObject, nexiPayLabelObject] = [
        "PayPalPayLabel",
        "stripePayLabel",
        "nexiPayLabel",
      ].map((key) => data.find((item) => item?.key === key));

      setPaypalBody(PayPalPayLabelObject?.body);
      setStripeBody(stripePayLabelObject?.body);
      setNexiBody(nexiPayLabelObject?.body);
      setBookingPaymentSubDescData(
        data.find((item) => item.key === "PaymentSubDescLabel")?.body
      );
    };
    fetchTextResources();
  }, [lang, textResource, translate]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  // ****************************** mode of payment logic **********************************
  const [allNewPaymentMethods, setAllNewPaymentMethods] = useState<
    Partial<AllPaymentMethodsResponse>
  >({});
  useEffect(() => {
    if (
      VehicleDetails.paymentDetails?.type?.toUpperCase() === "PAYLATER" ||
      reservationSourcePayLater == "true"
    ) {
      setAllNewPaymentMethods(allPayLaterMethods);
      //for pay later if only one payment method then by default it will be selected
      if (Object.keys(allPayLaterMethods).length == 1) {
        const [singlePaymentMethod] = Object.keys(allPayLaterMethods).map(
          (el) => el.toUpperCase()
        );
        setSelectedPaymentMethod(singlePaymentMethod);
      }
    } else {
      setAllNewPaymentMethods(allPayNowMethods);
      //for pay now if only one payment method then by default it will be selected
      if (Object.keys(allPayNowMethods).length == 1) {
        const [singlePaymentMethod] = Object.keys(allPayNowMethods).map((el) =>
          el.toUpperCase()
        );
        setSelectedPaymentMethod(singlePaymentMethod);
      }
    }
  }, [
    VehicleDetails.paymentDetails?.type,
    allPayLaterMethods,
    allPayNowMethods,
    reservationSourcePayLater,
  ]);
  useEffect(() => {
    if (currentLoggedInuser) {
      if (VehicleDetails.paymentDetails?.type?.toUpperCase() === "PAYLATER") {
        setSelectedPaymentMethod(Object.keys(allPayLaterMethods)[0]);
      } else {
        setSelectedPaymentMethod(Object.keys(allPayNowMethods)[0]);
      }
    }
  }, [
    currentLoggedInuser,
    VehicleDetails.paymentDetails?.type,
    allPayLaterMethods,
    allPayNowMethods,
  ]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!VehicleDetails?.paymentDetails?.price) {
        navigateTo("/");
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  createSearchParams({
    dropOffDateAndTime: new Date(ReservationDetails.dropOffDateAndTime)
      .getTime()
      .toString(),
    pickUpDateAndTime: new Date(ReservationDetails.pickUpDateAndTime)
      .getTime()
      .toString(),
    pickUpLocation: ReservationDetails.pickUpLocationDetails.code,
    dropOffLocation: ReservationDetails.dropOffLocationDetails.code,
    reservationSource: ReservationDetails.reservationSource.code,
    macroGroupName: ReservationDetails.macroGroupName,
    discountValueWithoutVat: ReservationDetails.discountValueWithoutVat,
    agreementCoupon: ReservationDetails.agreementCoupon,
    showPics: "true",
    showOptionalImage: "true",
    showVehicleParameter: "true",
    showVehicleExtraImage: "true",
    showBookingDiscount: "true",
    isYoungDriverAge: ReservationDetails.isYoungDriverAge,
    isYoungDriver2Age: ReservationDetails.isYoungDriver2Age,
    isSeniorDriverAge: ReservationDetails.isSeniorDriverAge,
    noFeeAge: ReservationDetails.noFeeAge,
  }).toString();

  // ******************************   new features added for rentalplus **********************************
  const [userType, setUserType] = useState("Private");
  const handleUserType = (userType) => {
    setUserType(userType);
  };
  useEffect(() => {
    requestParentResize(checked);
  }, [checked, userType]);

  function requestParentResize(checked) {
    let iframeHeight;
    if (checked) {
      if (userType === "Company") {
        iframeHeight = isSmallScreen ? 4517 : 2220;
      } else {
        iframeHeight = isSmallScreen ? 5189 : 2508;
      }
    } else {
      if (userType === "Company") {
        if (AppSettings?.showOnePayMethodTwoPrice) {
          //2100 - 217.6 - 8rem  (217.6 is payment type card height and 8rem is the gap
          iframeHeight = isSmallScreen ? 3810 : 1860;
        } else {
          iframeHeight = isSmallScreen ? 3810 : 2140;
        }
      } else if (userType === "Private") {
        if (AppSettings?.showOnePayMethodTwoPrice) {
          iframeHeight = isSmallScreen ? 3810 : 2000;
        } else {
          iframeHeight = isSmallScreen ? 3810 : 2250;
        }
      } else {
        if (AppSettings?.showOnePayMethodTwoPrice) {
          iframeHeight = isSmallScreen ? 4450 : 2151;
        } else {
          iframeHeight = isSmallScreen ? 4450 : 2430;
        }
      }
    }
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }

  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  const [selectedPrivacyPolicy, setSelectedPrivacyPolicy] = useState<string[]>(
    []
  );
  const [requiredPolicy, setRequiredPrivacyPolicy] = useState<string[]>([]);
  const [allPrivacyPolicy, setAllPrivacyPolicy] = useState<
    PrivacyPolicy["privacyPolicy"]
  >([]);
  useEffect(() => {
    if (appSettings?.showMyRentPolicies) {
      BookingApi.getPrivacyPolicy(null, "booking").then((response) => {
        const fetchedPolicies = response.data.privacyPolicy.map((item) => {
          const policyGroup = item.policyGroup;
          return policyGroup;
        });
        setAllPrivacyPolicy(fetchedPolicies);

        const getPolicies = (condition) =>
          fetchedPolicies.flatMap((policyArray) =>
            policyArray
              .filter(condition)
              .map((policy) =>
                i18n.language === "it"
                  ? policy.privacyPolicyLocalLang
                  : policy.privacyPolicyEn
              )
          );
        setSelectedPrivacyPolicy(
          getPolicies((policy) => policy.isDefaultAccepted)
        );
        setRequiredPrivacyPolicy(getPolicies((policy) => policy.isMandatory));
        setRequiredPrivacyPolicy(requiredPolicy);
      });
    }
  }, []);

  const updateSelectedPrivacyPolicy = (index, value) => {
    setSelectedPrivacyPolicy((prev) => {
      prev[index] = value;
      return [...prev];
    });
  };

  const [currentPriceToPay, setCurrentPriceToPay] = useState(0);
  const [percentageAmtOfExtras] = useState(
    AppSettings?.percentageAmountForExtras
  );
  const [percentOnAmount] = useState(AppSettings?.percentOnAmount);
  const [percentOnPayLaterAmount] = useState(
    AppSettings?.percentOnPayLaterAmount
  );
  const payType = VehicleDetails.paymentDetails?.type;
  useEffect(() => {
    const price = VehicleDetails?.paymentDetails?.price;
    if (percentOnAmount != null && (payType == "PAYNOW" || payType == "")) {
      let totalPrice = price;
      if (!VehicleDetails?.paymentDetails?.extrasIncluded) {
        totalPrice += extrasSelected?.extrasPrice;
      }
      const updatedPrice =
        (totalPrice ? totalPrice * percentOnAmount : 0) / 100;
      setCurrentPriceToPay(updatedPrice);
    } else if (percentOnPayLaterAmount != null && payType == "PAYLATER") {
      let totalPrice = price;
      if (!VehicleDetails?.paymentDetails?.extrasIncluded) {
        totalPrice += extrasSelected?.extrasPrice;
      }
      const updatedPrice =
        (totalPrice ? totalPrice * percentOnPayLaterAmount : 0) / 100;
      setCurrentPriceToPay(updatedPrice);
    } else if (percentageAmtOfExtras != null && price != null && price > 0) {
      // logic added to set how much % of extras & insurance amount customer will pay at the time of creating booking
      if (VehicleDetails?.paymentDetails?.extrasIncluded) {
        setCurrentPriceToPay(
          (extrasSelected?.extrasPrice *
            parseFloat(percentageAmtOfExtras.toString())) /
            100 +
            parseFloat(price.toString()) -
            extrasSelected?.extrasPrice
        );
      } else {
        setCurrentPriceToPay(
          (extrasSelected?.extrasPrice *
            parseFloat(percentageAmtOfExtras.toString())) /
            100 +
            parseFloat(price.toString())
        );
      }
    } else if (VehicleDetails?.paymentDetails?.price != null) {
      if (VehicleDetails?.paymentDetails?.extrasIncluded) {
        setCurrentPriceToPay(VehicleDetails?.paymentDetails?.price);
      } else {
        setCurrentPriceToPay(
          VehicleDetails?.paymentDetails?.price + extrasSelected?.extrasPrice
        );
      }
    }
  }, [percentageAmtOfExtras, VehicleDetails?.paymentDetails, extrasSelected]);
  // ------------------------ logic end  ----------------------------------------

  const [loading, setLoading] = useState(false);
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  useEffect(() => {
    if (loading) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loading]);

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isCloseButton, setIsCloseButton] = useState(false);

  const onRequestClose = () => {
    setOpenModal(false);
    setModalMessage("");
    if (isCloseButton == true) {
      updateActiveStep();
    }
  };

  const novehicleModal = (msg) => {
    setModalMessage(msg);
    setOpenModal(true);
    setIsCloseButton(true);
  };
  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <section
      className="flex flex-col "
      style={
        isSmallScreen
          ? { width: "99%", overflow: "hidden" }
          : { width: "100%", overflow: "hidden" }
      }
    >
      <AlertMessage
        openModal={openModal}
        requestOnClose={onRequestClose}
        modalMessage={modalMessage}
      />
      <div
        className={`flex justify-center items-center ${
          isSmallScreen ? "mt-[10%]" : ""
        }`}
      ></div>
      <div className="grow flex flex-col grow gap-16 box-border p-6 bg-gray-100">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!AppSettings?.showOldPaymentForm && !isValidEmail(email)) {
              setEmailError("Invalid email format");
              emailRef.current ? emailRef.current.focus() : "";
            }
            if (
              (phoneNo == "" || phoneNo.length == 2) &&
              AppSettings?.markPhoneNoRequiredForPrivate
            ) {
              setPhoneError("Phone No is required");
              setInvalidError("Phone No is required");
              // phoneRef.current ? phoneRef.current.focus() : "";
            } else if (UserDetails.dateOfBirth == "" && userType == "Private") {
              setDateError("This is a required field");
              setInvalidError("DOB is required field");
            } else {
              setEmailError("");
              setPhoneError("");
              setInvalidError("");
              setIsSaving(true);
              setLoading(true);
              BookingApi.createBooking(
                firstName,
                lastName,
                email,
                phoneNo,
                ReservationDetails,
                UserDetails,
                VehicleDetails.vehicleDetails as Vehicle,
                VehicleDetails.paymentDetails as SelectedVehicleDetails,
                selectedPaymentMethod,
                companyinvoiceDetails,
                currentLoggedInuser,
                whetherIfGroupIsOnRequest,
                userType,
                checked,
                AppSettings?.payWiretransfer,
                AppSettings?.payVpos,
                extrasSelected?.selectedInsurance?.insuranceId,
                extrasSelected?.addedExtraOptions,
                extrasSelected?.extrasPrice
              ).then((e) => {
                if (e.toString() === "invalid.customer.ssn") {
                  setIsSaving(false);
                  setLoading(false);
                  setModalMessage("Invalid Tax Code");
                  setOpenModal(true);
                } else if (e.toString() === "invalid.companyTaxCode") {
                  setIsSaving(false);
                  setLoading(false);
                  setModalMessage("Invalid Company Tax Code");
                  setOpenModal(true);
                } else if (e.toString() === "invalid.company.vat") {
                  setIsSaving(false);
                  setLoading(false);
                  setModalMessage("Invalid VAT");
                  setOpenModal(true);
                } else {
                  if (appSettings?.showMyRentPolicies) {
                    const policyIds = selectedPrivacyPolicy.map(
                      (selectedPolicy) => {
                        const selectedPolicyIds: number[] = [];
                        let foundPolicy: number | undefined;
                        if (Array.isArray(selectedPolicy)) {
                          for (const p1 of selectedPolicy) {
                            let policyFound = false;
                            for (const p2 of allPrivacyPolicy) {
                              const foundPolicy = p2.find((policy) => {
                                const policyData = p1.startsWith("*")
                                  ? p1.substring(1)
                                  : p1;
                                return (
                                  policyData ===
                                  (i18n.language == "it"
                                    ? policy.privacyPolicyLocalLang
                                    : policy.privacyPolicyEn)
                                );
                              });
                              if (foundPolicy) {
                                selectedPolicyIds.push(foundPolicy.id);
                                policyFound = true;
                                break;
                              }
                            }
                            if (!policyFound) {
                              selectedPolicyIds.push(-1);
                            }
                          }
                        }
                        return selectedPolicyIds;
                      }
                    );
                    BookingApi.savePrivacyPolicy(
                      e?.data?.data?.dbId,
                      policyIds.flat()
                    ); // Flatten nested arrays before saving for single array
                  }

                  setReservationId(e?.data.data?.dbId);
                  setIsSaving(false);
                  setLoading(false);
                  if (e.data.data == undefined) {
                    novehicleModal(e.data.message);
                  } else {
                    switch (selectedPaymentMethod.toLowerCase()) {
                      case "paypal": {
                        payPalFormRef?.current?.submit();
                        break;
                      }
                      case "nexi": {
                        if (
                          AppSettings?.onrequestPayAmount &&
                          VehicleDetails.vehicleDetails?.Status.toLowerCase() ==
                            "onrequest"
                        ) {
                          BookingApi.getPaymentLink(
                            e.data.data.dbId,
                            0.01,
                            "nexi"
                          ).then((e) => {
                            if (e.generateLink) {
                              window.scrollTo(0, 0);
                              scrollToTop();
                              window.open(e.generateLink, "_self");
                              window.scrollTo(0, 0);
                              scrollToTop();
                            }
                          });
                        } else {
                          BookingApi.getPaymentLink(
                            e.data.data.dbId,
                            currentPriceToPay || 0,
                            "nexi"
                          ).then((e) => {
                            if (e.generateLink) {
                              window.scrollTo(0, 0);
                              scrollToTop();
                              window.open(e.generateLink, "_self");
                              window.scrollTo(0, 0);
                              scrollToTop();
                            }
                          });
                        }
                        break;
                      }
                      case "stripe": {
                        BookingApi.getPaymentLink(
                          e.data.data.dbId,
                          currentPriceToPay || 0,
                          "Stripe"
                        ).then((e) => {
                          if (e.paymentUrl) {
                            const paymentData = {
                              ...e,
                              paymentMode: "Stripe",
                            };
                            window.open(paymentData.paymentUrl, "_self");
                          }
                        });
                        break;
                      }
                      case "wiretransfer": {
                        let dbId = e.data.data?.id;
                        dbId = dbId.split(" ").slice(0, 2).join(" ");
                        const ResId = e.data.data?.dbId;
                        console.log("*****ResId****", ResId);
                        BookingApi.sendEmailForWireTransfer(
                          e.data.data?.dbId,
                          whetherIfGroupIsOnRequest,
                          i18n.language
                        );
                        navigateTo(
                          `/booking/confirmed?dbId=${dbId}&ResId=${ResId}&vehicleName=${e.data.data.Vehicle.VehMakeModel.Name}&vehicleCategory=${e.data.data.Vehicle.Code}&DropOffUpDateTime=${e.data.data.ReturnDateTime}&importo=${currentPriceToPay}`
                        );

                        break;
                      }
                      default: {
                        BookingApi.getPaymentLink(
                          e.data.data.dbId,
                          currentPriceToPay || 0,
                          "nexi"
                        ).then((e) => {
                          if (e.generateLink) {
                            window.scrollTo(0, 0);
                            scrollToTop();
                            window.open(e.generateLink, "_self");
                            window.scrollTo(0, 0);
                            scrollToTop();
                          }
                        });
                        return;
                      }
                    }
                  }
                }
              });
            }
          }}
          className="grow flex flex-col grow gap-16 box-border p-4 bg-gray-100 -align-center"
        >
          {AppSettings?.showOldPaymentForm ? (
            AppSettings?.showCompanyDetailsPriorPersonal ? (
              <>
                <CompanyDetails
                  checked={checked}
                  handleChange={handleChange}
                  userType={userType}
                  handleUserType={handleUserType}
                  setCompanyinvoiceDetails={setCompanyinvoiceDetails}
                  companyinvoiceDetails={companyinvoiceDetails}
                />
                <PersonalDetails
                  reservationSourcePayNow={reservationSourcePayNow}
                  reservationSourcePayLater={reservationSourcePayLater}
                  extrasSelected={extrasSelected}
                  updateVehiclePrice={updateVehiclePrice}
                  updateSelectedPrice={updateSelectedPrice}
                />
              </>
            ) : (
              <>
                <PersonalDetails
                  reservationSourcePayNow={reservationSourcePayNow}
                  reservationSourcePayLater={reservationSourcePayLater}
                  extrasSelected={extrasSelected}
                  updateVehiclePrice={updateVehiclePrice}
                  updateSelectedPrice={updateSelectedPrice}
                />
                <CompanyDetails
                  checked={checked}
                  handleChange={handleChange}
                  userType={userType}
                  handleUserType={handleUserType}
                  setCompanyinvoiceDetails={setCompanyinvoiceDetails}
                  companyinvoiceDetails={companyinvoiceDetails}
                />
              </>
            )
          ) : (
            <>
              <NewPersonalDetails
                reservationSourcePayNow={reservationSourcePayNow}
                reservationSourcePayLater={reservationSourcePayLater}
                extrasSelected={extrasSelected}
                updateVehiclePrice={updateVehiclePrice}
                updateSelectedPrice={updateSelectedPrice}
                updateUserPrimaryDetails={updateUserPrimaryDetails}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phoneNo={phoneNo}
                emailError={emailError}
                emailRef={emailRef}
                phoneRef={phoneRef}
                phoneError={phoneError}
              />
              <NewCompanyDetails
                isAirport={isAirport}
                userType={userType}
                handleUserType={handleUserType}
                setCompanyinvoiceDetails={setCompanyinvoiceDetails}
                companyinvoiceDetails={companyinvoiceDetails}
                phoneRef={phoneRef}
                phoneError={phoneError}
                dateError={dateError}
              />
            </>
          )}
          <TermsAndConditions
            updateSelectedPrivacyPolicy={updateSelectedPrivacyPolicy}
            selectedPrivacyPolicy={selectedPrivacyPolicy}
            allPrivacyPolicy={allPrivacyPolicy}
            requiredPolicy={requiredPolicy}
          />
          <footer className="flex flex-row flex-wrap bg-white box-border p-8">
            <div className="flex flex-col grow">
              {!currentLoggedInuser ||
              !AppSettings?.reservedAreaShowOnlyOnePayment ? (
                <AppRadioButton
                  required={true}
                  color={"primary"}
                  label={
                    <span style={{ fontFamily: fontFamilyStyle }}>
                      {t("Mode of Payment")}
                    </span>
                  }
                  value={selectedPaymentMethod}
                  options={Object.keys(allNewPaymentMethods)
                    .map((key) => {
                      switch (key.toUpperCase()) {
                        case "WIRETRANSFER":
                          return {
                            label: t("Pay at desk"),
                            value: "WIRETRANSFER",
                          };
                        case "PAYPAL":
                          return {
                            label: paypalBody ? paypalBody : t("PayPal"),
                            value: "PAYPAL",
                          };
                        case "NEXI":
                          return {
                            label: nexiBody ? nexiBody : t("Credit card"),
                            value: "NEXI",
                          };
                        case "STRIPE":
                          return {
                            label: stripeBody ? stripeBody : t("Stripe"),
                            value: "STRIPE",
                          };
                        default:
                          return {
                            label: "",
                            value: "",
                          };
                      }
                    })
                    .flat()
                    .filter((option) => option !== null)}
                  onChange={(e) => {
                    setSelectedPaymentMethod(e);
                  }}
                />
              ) : null}
            </div>

            <div className="flex flex-row gap-6">
              <p className="text-red-500">{invalidError}</p>
              <AppButton
                loading={isSaving}
                type="submit"
                color="primary"
                className="h-12 w-44 flex justify-center rounded-sm"
              >
                <span
                  className="flex text-center w-full h-full mt-3 justify-center"
                  style={{ fontFamily: fontFamilyStyle }}
                >
                  {t("Confirm")}
                </span>
              </AppButton>
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: bookingPaymentSubDescData || "",
              }}
              className={"text-black"}
            />
            <div className="flex flex-row  text-[#707070] mt-4 mb-2">
              {AppSettings?.onrequestPayAmount &&
              VehicleDetails.vehicleDetails?.Status.toLowerCase() == "onrequest"
                ? t(
                    "The reservation will be subjected to confirmation – during the payment process, you will be asked to pay €0.01 and, once the reservation is confirmed, you will be charged the total amount of the rental on the registered credit card."
                  )
                : ""}
            </div>
          </footer>
        </form>
      </div>
      <div className={"text-black hidden"}>
        {allNewPaymentMethods?.paypal &&
          allNewPaymentMethods?.paypal?.length > 0 && (
            <PayPal
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={payPalFormRef}
              postUrl={
                allNewPaymentMethods.paypal[0]?.developmentMode
                  ? allNewPaymentMethods.paypal[0]?.sandboxPaymentURL || ""
                  : allNewPaymentMethods.paypal[0]?.productionPaymentURL || ""
              }
              companyEmail={
                allNewPaymentMethods.paypal[0]?.developmentMode
                  ? allNewPaymentMethods.paypal[0]?.sandboxEmailCompany || ""
                  : allNewPaymentMethods.paypal[0]?.productionEmailCompany || ""
              }
              amount={currentPriceToPay || 0}
              returnLink={
                allNewPaymentMethods.paypal[0]?.developmentMode
                  ? allNewPaymentMethods.paypal[0]?.sandboxReturnURL || ""
                  : allNewPaymentMethods.paypal[0]?.productionReturnURL || ""
              }
              notify_url={
                allNewPaymentMethods.paypal[0]?.developmentMode
                  ? allNewPaymentMethods.paypal[0]?.sandboxNotifyURL || ""
                  : allNewPaymentMethods.paypal[0]?.productionNotifyURL || ""
              }
              cancel_url={
                allNewPaymentMethods.paypal[0]?.developmentMode
                  ? allNewPaymentMethods.paypal[0]?.sandboxCancelURL || ""
                  : allNewPaymentMethods.paypal[0]?.productionCancelURL || ""
              }
              itemName={"NAME HERE"}
              reservationId={ReservationId}
            />
          )}
      </div>
    </section>
  );
}
export { PaymentDetailsPage };
