import React, { useEffect, useRef, useState } from "react";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingApi from "../../API/Apis/BookingApi";
import {
  AffiliateDetails,
  BookingDetails,
} from "../../@types/Booking/bookingDetails";
import { BookingGetters } from "../../store/onlineBooking/getters";
import { StateSelectedVehicleDetails } from "../../@types/states/StateSelectedVehicleDetails";
import { TimeInMinutes } from "../../helpers/timerConfig";
import { Vehicle } from "../../@types/DTO/Vehicles.d";
import { addMinutes } from "date-fns";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ShowVehicles } from "../../components/Layouts/ShowVehicles/ShowVehicles";
import { ShowVehiclesProps } from "../../components/Layouts/ShowVehicles/ShowVehiclesProps";
import { Button, CircularProgress, Drawer } from "@mui/material";
import AppButton from "../../components/UI/AppButtons";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";
import { BookingUrlParams } from "../../@types/Booking/linkRedirection";
import { useTranslation } from "react-i18next";
import "./Scroller.css";
import { SelectVehicleExtras } from "../../components/Layouts/VehicleExtraSelections/SelectVehicleExtra";
import { ChosenOptions } from "../../components/Layouts/BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import { InsuranceDetails } from "../../@types/DTO/ApiResponses";
import { InsuranceCardsLayout } from "../../components/Layouts/Insurance/Insurance";
import theme, { formatTheme } from "../../config/MuiColorThemes";
import { format } from "date-fns";
import { SelectedVehicleExtraOptions } from "../../@types/VehicleOptions";
import { PaymentDetailsPage } from "../pay";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import FilterAlt from "@mui/icons-material/FilterAlt";
import InsurancePreview from "../../components/Layouts/Insurance/InsurancePreview";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { AllPaymentMethodsResponse } from "../../@types/DTO/AllPaymentMethods";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import i18n from "../../config/i18n";
function ChooseVehiclePage() {
  const { t: translate } = useTranslation();
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState<boolean>(true);
  const appSettings = BookingGetters.getAppSettings();
  const recapCardBackgroundColor =
    appSettings && appSettings["extrasBackgroundColor"];
  const filterBackgroundColor =
    appSettings && appSettings["filterBackgroundColor"];
  const secondPageBackgroundColor =
    appSettings && appSettings["secondPageBackgroundColor"];
  const showFilterAtTop = appSettings?.showFilterAtTop;
  const handleEdit = () => {
    navigation("/embed");
  };
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<
    StateSelectedVehicleDetails["vehicleDetails"] | undefined
  >();
  const bookingInformation: BookingDetails = BookingGetters.getBookingDetails();
  const reduxBookingDetails: BookingDetails =
    BookingGetters.getBookingDetails();
  const [searchParams] = useSearchParams();
  const [discountValueWithoutVat, setDiscountValueWithoutVat] =
    useState<string>(
      reduxBookingDetails.discountValueWithoutVat
        ? reduxBookingDetails.discountValueWithoutVat
        : "0"
    );
  const navigation = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [pushState, setPushState] = React.useState(0); // pushState is used to store the history pushState for back button functionality
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [hasShownInsuranceTab, setHasShownInsuaranceTab] =
    useState<boolean>(false);
  const [activeTab] = useState<string>("0");
  const [selectedFields, setSelectedFields] = useState<ChosenOptions>({
    options: {},
    insurance: undefined,
  });
  const [userId] = useState(localStorage.getItem("userID"));
  const [discount, setDiscount] = useState<number>(0);
  useEffect(() => {
    if (userId != null && userId != "null" && userId != "") {
      setDiscount(0);
    } else {
      setDiscount((100 - (selectedVehicle?.Vehicle?.payNowDis || 0)) / 100);
    }
  }, [userId, selectedVehicle?.Vehicle?.payNowDis]);

  const [totalPrice, setTotalPrice] = useState<number>(
    searchParams.get("reservationSourcePayLater") === "true" ||
      (userId != null && userId != "null" && userId != "")
      ? parseFloat(
          (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
            ? (
                selectedVehicle?.Reference?.originalAmount -
                selectedVehicle?.Reference.ID
              ).toString()
            : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
        )
      : parseFloat(
          (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
            ? (
                selectedVehicle?.Reference?.originalAmount -
                selectedVehicle?.Reference.ID
              ).toString()
            : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
        ) * discount
  );
  const [insuranceList, setInsuranceList] = useState<InsuranceDetails>({
    "Booking Insurance": [],
    Specification: [],
  });
  const [groupName, setGroupName] = useState<string>("");
  const [errorMsgNoVehicle, setErrorMsgNoVehicle] = useState<string>("");
  const [showInfo, setShowInfo] = useState(false);
  const toogleVehicleInfoCardShowingStatus = () => {
    setShowInfo(!showInfo);
  };
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [reservedArea, setReservedArea] = useState(false);
  const fontFamilyStyle = appSettings?.fontFamily;
  const enableFilterBySeat = appSettings && appSettings["enableFilterBySeat"];
  const redirectUrl = appSettings && appSettings["redirectUrl"];
  const enableFilterByFuel = appSettings && appSettings["enableFilterByFuel"];
  const enableFilterByTransmission =
    appSettings && appSettings["enableFilterByTransmission"];
  const [extrasPrice, setExtrasPrice] = useState(0); // extras and insurance price
  const [onlyExtrasPrice, setOnlyExtrasPrice] = useState<number>(0); // extras price
  const [insurancePrice, setInsurancePrice] = useState<number>(0); // insurance price
  const [vehiclePrice, setVehiclePrice] = useState<number>(0); // vehicle price
  const [showInsurancePreview, setShowInsurancePreview] = useState(
    appSettings?.showInsurancePreview
  );

  const updateShowInsurance = () => {
    if (appSettings?.showInsurancePreview) {
      setSelectedFields((prevState) => ({
        ...prevState,
        insurance: undefined,
      }));
      setShowInsurancePreview(false);
    }
  };
  // ----------- Filters  -------------------
  const [tempAvailabilityFilter, setTempAvailabilityFilter] = useState<
    ShowVehiclesProps["vehiclesFilter"]
  >({
    Transmission: {},
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
  });
  const [filterValue, setFilterValue] = useState<
    ShowVehiclesProps["vehiclesFilter"]
  >({
    Transmission: {},
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
  });
  const [pickUpLabel, setPickUpLabel] = useState<string>("");
  const [dropOffLabel, setDropOffLabel] = useState<string>("");
  const [transmissionLabel, setTransmissionLabel] = useState<string>("");
  const [seatsLabel, setSeatsLabel] = useState<string>("");
  const [fuelLabel, setFuelLabel] = useState<string>("");
  const lang = i18n.language;
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  useEffect(() => {
    const pickUpLabelData = data.find(
      (item) => item.key === "PickUpLocationLabel"
    );
    const DropOffLabelData = data.find(
      (item) => item.key === "DropOffLocationLabel"
    );
    const transmissionData = data.find(
      (item) => item.key === "transmissionLabel"
    );
    const seatsData = data.find((item) => item.key === "seatsLabel");
    const fuelData = data.find((item) => item.key === "fuelLabel");
    setPickUpLabel(pickUpLabelData?.body || translate("Pick Up"));
    setDropOffLabel(DropOffLabelData?.body || translate("Drop Off"));
    setTransmissionLabel(transmissionData?.body || translate("Transmission"));
    setSeatsLabel(seatsData?.body || translate("No. of Seats"));
    setFuelLabel(fuelData?.body || translate("Type of Fuel"));
  }, [lang, textResource, translate]);
  const [filterfuelTypeData, setFilterfuelTypeData] = useState([]);
  const [filterseatData, setFilterseatData] = useState([]);
  const [filtertransmissionData, setFiltertransmissionData] = useState([]);
  const transmissionHeader =
    filtertransmissionData != null && filtertransmissionData.length > 0
      ? transmissionLabel
      : "";
  const seatHeader =
    filterseatData != null && filterseatData.length > 0 ? seatsLabel : "";
  const fuelTypeHeader =
    filterfuelTypeData != null && filterfuelTypeData.length > 0
      ? fuelLabel
      : "";
  useEffect(() => {
    const timer = setTimeout(() => {
      setFilterValue(tempAvailabilityFilter);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [tempAvailabilityFilter]);
  const updateActiveStep = () => {
    setActiveStep(0);
    setPushState(0);
    scrollToTop();
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    BookingApi.getBookingFilterMethods().then((response) => {
      const filterMethods = response.data;
      setFilterfuelTypeData(filterMethods?.fuelType);
      setFilterseatData(filterMethods?.noOfSeatSelect);
      setFiltertransmissionData(filterMethods?.transmission);
    });
  }, []);

  const hideIframe = () => {
    window.parent.postMessage(
      {
        event_id: "hideIframe",
      },
      "*"
    );
  };

  const updateSelectedPrice = (price) => {
    const parsedPrice = parseFloat(price);
    if (!isNaN(parsedPrice)) {
      setSelectedPrice(parsedPrice);
    }
  };
  const selectVehicleExtrasRef = useRef<HTMLDivElement>(null); //this is used so that for mobile view if insurance selected then extra componet will be scrolled to top
  // // -----------------------------Code for browser back button functionality----------------
  useEffect(() => {
    const state = { step: pushState };
    window.history.pushState(state, `Step ${pushState}`, "");
  }, [pushState]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.step !== undefined) {
        setActiveStep(event.state.step);
      } else {
        console.log(
          "In else block for history push state where state is undefined"
        );
        setActiveStep(0);
      }
    };
    window.addEventListener("popstate", handlePopState);
    if (window.history.state && window.history.state.step !== undefined) {
      setActiveStep(window.history.state.step);
    }
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  function VehicleFilters() {
    return (
      <>
        {(enableFilterByFuel && filterfuelTypeData.length > 0) ||
        (enableFilterBySeat && filterseatData.length > 0) ||
        (enableFilterByTransmission && filtertransmissionData.length > 0) ? (
          <h2 className="font-bold text-xl text-gray-600 pb-8 box-border">
            {t("Filter to Vehicles")}
          </h2>
        ) : null}
        <div
          className={
            "h-full overflow-hidden hover:overflow-auto flex flex-col box-border px-4"
          }
        >
          {enableFilterByFuel && (
            <AppStandardMultiSelectCheckBox
              value={Object.values(tempAvailabilityFilter?.type || {})}
              onChange={async (e) => {
                const type1 = (e || []).reduce((prev, current) => {
                  prev[current.toLowerCase()] = current;
                  return prev;
                }, {});
                setTempAvailabilityFilter({
                  type: type1,
                  category: tempAvailabilityFilter?.category || {},
                  status: tempAvailabilityFilter?.status || {},
                  TotalSeats: tempAvailabilityFilter?.TotalSeats || [],
                  MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                  Transmission: tempAvailabilityFilter?.Transmission || {},
                });
              }}
              header={fuelTypeHeader}
              options={filterfuelTypeData.map((fuelType) => {
                return {
                  label: translate(fuelType),
                  value: fuelType,
                };
              })}
              color={"primary"}
            />
          )}

          {enableFilterBySeat && (
            <AppStandardMultiSelectCheckBox
              value={Object.values(tempAvailabilityFilter?.TotalSeats || {})}
              color={"primary"}
              onChange={async (e) => {
                setTempAvailabilityFilter({
                  type: tempAvailabilityFilter?.type || {},
                  category: tempAvailabilityFilter?.category || {},
                  status: tempAvailabilityFilter?.status || {},
                  TotalSeats: e || [],
                  MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                  Transmission: tempAvailabilityFilter?.Transmission || {},
                });
              }}
              header={seatHeader}
              options={filterseatData.map((seats) => {
                return {
                  label: `${translate(seats)} ${t("seats")}`,
                  value: seats,
                };
              })}
            />
          )}

          {enableFilterByTransmission && (
            <AppStandardMultiSelectCheckBox
              value={Object.values(tempAvailabilityFilter?.Transmission || {})}
              color={"primary"}
              onChange={async (e) => {
                setTempAvailabilityFilter({
                  type: tempAvailabilityFilter?.type || {},
                  category: tempAvailabilityFilter?.category || {},
                  status: tempAvailabilityFilter?.status || {},
                  TotalSeats: tempAvailabilityFilter?.TotalSeats || [],
                  MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                  Transmission: (e || []).reduce((prev, current) => {
                    prev[current.toLowerCase()] = current;
                    return prev;
                  }, {}),
                });
              }}
              header={transmissionHeader}
              options={filtertransmissionData.map((transmission) => {
                return {
                  label: translate(transmission),
                  value: transmission,
                };
              })}
            />
          )}
        </div>
      </>
    );
  }

  function VehicleFiltersAtTop() {
    return (
      <>
        <div
          className={"h-full block rounded mt-[25px] mb-[25px]"}
          style={{
            paddingLeft: "7px",
            background: filterBackgroundColor
              ? filterBackgroundColor
              : "#ffffff",
          }}
        >
          <div
            className="{ flex flex-row justify-center box-border}"
            style={{
              paddingLeft: "7px",
              background: filterBackgroundColor
                ? filterBackgroundColor
                : "#ffffff",
            }}
          >
            {(enableFilterByFuel && filterfuelTypeData.length > 0) ||
            (enableFilterBySeat && filterseatData.length > 0) ||
            (enableFilterByTransmission &&
              filtertransmissionData.length > 0) ? (
              <span className="font-bold text-sm text-gray-600 box-border gap-2 pt-[9px] whitespace-nowrap">
                {t("Filtra Per")}
              </span>
            ) : null}
            <span
              style={{
                width: enableFilterByFuel ? "193px" : "0px",
                marginRight: enableFilterByFuel ? "-40px" : "0px",
              }}
            >
              {enableFilterByFuel && (
                <AppInputSwitcher
                  required={false}
                  className={"w-[70%] ml-[20%] bg-white"}
                  type="select"
                  variant="with-search"
                  containerheight={"40px"}
                  label={translate("Fuel")}
                  options={[
                    { label: translate("Select Fuel"), value: "" },
                    ...filterfuelTypeData.map((fuelType) => {
                      return {
                        label: translate(fuelType),
                        value: fuelType,
                      };
                    }),
                  ]}
                  onchange={async (e) => {
                    const selectedValues = Array.isArray(e) ? e : [e];
                    const type1 = selectedValues.reduce((prev, current) => {
                      prev[current.toLowerCase()] = current;
                      return prev;
                    }, {});
                    setTempAvailabilityFilter({
                      type: type1,
                      category: tempAvailabilityFilter?.category || {},
                      status: tempAvailabilityFilter?.status || {},
                      TotalSeats: tempAvailabilityFilter?.TotalSeats || [],
                      MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                      Transmission: tempAvailabilityFilter?.Transmission || {},
                    });
                  }}
                  value={Object.values(tempAvailabilityFilter?.type || {})[0]}
                />
              )}
            </span>
            <span
              style={{
                width: enableFilterBySeat ? "185px" : "0px",
                marginRight: enableFilterBySeat ? "-7px" : "0px",
              }}
            >
              {enableFilterBySeat && (
                <AppInputSwitcher
                  required={false}
                  className={"w-[70%] ml-[20%] bg-white"}
                  containerheight={"40px"}
                  type="select"
                  variant="with-search"
                  label={translate("seats")}
                  options={[
                    { label: translate("Select Seat"), value: [] },
                    ...filterseatData.map((seats) => {
                      return {
                        label: translate(seats) + " " + translate("seats"),
                        value: seats,
                      };
                    }),
                  ]}
                  value={
                    tempAvailabilityFilter?.TotalSeats &&
                    tempAvailabilityFilter.TotalSeats.length > 0
                      ? Object.values(tempAvailabilityFilter.TotalSeats)[0] +
                        " seats"
                      : ""
                  }
                  onchange={async (e) => {
                    const selectedSeats = Array.isArray(e) ? e : [e];
                    setTempAvailabilityFilter({
                      type: tempAvailabilityFilter?.type || {},
                      category: tempAvailabilityFilter?.category || {},
                      status: tempAvailabilityFilter?.status || {},
                      TotalSeats: selectedSeats || [],
                      MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                      Transmission: tempAvailabilityFilter?.Transmission || {},
                    });
                  }}
                />
              )}
            </span>
            <span
              style={{
                width: enableFilterBySeat ? "210px" : "0px",
                marginRight: enableFilterBySeat ? "-58px" : "0px",
              }}
            >
              {enableFilterByTransmission && (
                <AppInputSwitcher
                  required={false}
                  className={"w-[70%] bg-white"}
                  containerheight={"40px"}
                  type="select"
                  variant="with-search"
                  label={translate("Transmission")}
                  options={[
                    { label: translate("Select Transmission"), value: "" },
                    ...filtertransmissionData.map((transmission) => {
                      return {
                        label: translate(transmission),
                        value: transmission,
                      };
                    }),
                  ]}
                  value={
                    Object.values(tempAvailabilityFilter?.Transmission || {})[0]
                  }
                  onchange={async (e) => {
                    const transmission = (Array.isArray(e) ? e : [e]).reduce(
                      (prev, current) => {
                        prev[current.toLowerCase()] = current;
                        return prev;
                      },
                      {}
                    );
                    setTempAvailabilityFilter({
                      type: tempAvailabilityFilter?.type || {},
                      category: tempAvailabilityFilter?.category || {},
                      status: tempAvailabilityFilter?.status || {},
                      TotalSeats: tempAvailabilityFilter?.TotalSeats || [],
                      MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                      Transmission: transmission,
                    });
                  }}
                />
              )}
            </span>
          </div>
        </div>
      </>
    );
  }

  // --------------- END FILTER --------------------------
  useEffect(() => {
    if (userId != null && userId != "null" && userId != "") {
      setReservedArea(true);
    } else {
      setReservedArea(false);
    }
  }, [userId]);
  useEffect(() => {
    setHasShownInsuaranceTab(hasShownInsuranceTab || activeTab == "1");
  }, [activeTab]);

  const [resSourceFound, setResSourceFound] = useState(false);
  useEffect(() => {
    let price: number = parseFloat(
      selectedFields.insurance?.price?.toString() || "0"
    );
    setInsurancePrice(price);
    let selectedExtras: number = parseFloat("0");
    if (selectedFields.options) {
      Object.values<SelectedVehicleExtraOptions>(
        selectedFields.options || {}
      ).map((el) => {
        price += el.price * el.quantity;
        selectedExtras += el.price * el.quantity;
      });
    }
    setExtrasPrice(price);
    let totalPrice;
    if (
      searchParams.get("reservationSourcePayLater") === "true" ||
      (userId != null && userId != "null" && userId != "")
    ) {
      totalPrice = parseFloat(
        (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
          ? (
              selectedVehicle?.Reference?.originalAmount -
              selectedVehicle?.Reference.ID
            ).toString()
          : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
      );
    } else {
      totalPrice =
        parseFloat(
          (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
            ? (
                selectedVehicle?.Reference?.originalAmount -
                selectedVehicle?.Reference.ID
              ).toString()
            : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
        ) * discount;
    }
    const totalPriceAfterExtra = price + totalPrice;
    setOnlyExtrasPrice(selectedExtras);
    setVehiclePrice(totalPrice);
    setTotalPrice(totalPriceAfterExtra);
  }, [selectedFields, selectedVehicle?.TotalCharge?.RateTotalAmount, discount]);

  const updateVehiclePrice = (price) => {
    const totalPriceAfterExtra = insurancePrice + onlyExtrasPrice + price;
    setTotalPrice(totalPriceAfterExtra);
    setVehiclePrice(price);
  };
  const extrasAndInsurance =
    !appSettings?.donotShowInsurance ||
    (!appSettings?.donotShowExtras && selectedFields.options != null);
  const [reservationSourcePayNow, setReservationSourcePayNow] =
    useState<string>("false");
  const isAirport = searchParams.get("isAirport");
  const [reservationSourcePayLater, setReservationSourcePayLater] =
    useState<string>("false");
  useEffect(() => {
    setIsLoading(true);
    const t: BookingUrlParams = {
      dropOffDateAndTime: searchParams.get("dropOffDateAndTime") || "",
      pickUpDateAndTime: searchParams.get("pickUpDateAndTime") || "",
      pickUpLocation: searchParams.get("pickUpLocation") || "",
      dropOffLocation: searchParams.get("dropOffLocation") || "",
      pickUpLocationName: searchParams.get("pickUpLocationName") || "",
      dropOffLocationName: searchParams.get("dropOffLocationName") || "",
      reservationSource: searchParams.get("reservationSource") || "WEB001",
      macroGroupName: searchParams.get("macroGroupName") || " ",
      discountValueWithoutVat:
        searchParams.get("discountValueWithoutVat") || " ",
      showPics: searchParams.get("showPics") || "true",
      showOptionalImage: searchParams.get("showOptionalImage") || "true",
      showVehicleParameter: searchParams.get("showVehicleParameter") || "true",
      showBookingDiscount: searchParams.get("showBookingDiscount") || "true",
      agreementCoupon: searchParams.get("agreementCoupon") || " ",
      showVehicleExtraImage:
        searchParams.get("showVehicleExtraImage") || "true",
      isYoungDriverAge: searchParams.get("isYoungDriverAge") || "",
      isYoungDriver2Age: searchParams.get("isYoungDriver2Age") || "",
      isSeniorDriverAge: searchParams.get("isSeniorDriverAge") || "",
      noFeeAge: searchParams.get("noFeeAge") || "",
      isAgeLimitSelected: searchParams.get("isAgeLimitSelected") || "",
      reservationSourcePayNow:
        searchParams.get("reservationSourcePayNow") || "false",
      reservationSourcePayLater:
        searchParams.get("reservationSourcePayLater") || "false",
      youngDriverAgeValue: searchParams.get("youngDriverAgeValue") || "",
      youngDriver2AgeValue: searchParams.get("youngDriver2AgeValue") || "",
      seniorDriverAgeValue: searchParams.get("seniorDriverAgeValue") || "",
      noAgeDriverValue: searchParams.get("noAgeDriverValue") || "",
      affiliateName: searchParams.get("affiliateName") || "",
      affiliateId: searchParams.get("affiliateId") || "",
    };
    for (const i in t) {
      if (!t[i]) {
        navigation("/");
      }
    }
    setReservationSourcePayNow(
      searchParams.get("reservationSourcePayNow") || ""
    );
    setReservationSourcePayLater(
      searchParams.get("reservationSourcePayLater") || ""
    );

    const currentBookingDetails: BookingDetails = {
      pickUpDateAndTime: new Date(parseInt(t.pickUpDateAndTime)).getTime(),
      dropOffDateAndTime: new Date(parseInt(t.dropOffDateAndTime)).getTime(),
      dropOffLocationDetails: {
        name: "",
        code: t.dropOffLocation,
        locationNumber: "",
        locationAddress: "",
        locationCity: "",
        zipCode: "",
        publicWebDescription: "",
        publicWebDescriptionEN: "",
      },
      pickUpLocationDetails: {
        name: "",
        code: t.pickUpLocation,
        locationNumber: "",
        locationAddress: "",
        locationCity: "",
        zipCode: "",
        publicWebDescription: "",
        publicWebDescriptionEN: "",
      },
      reservationSource: {
        name: "",
        code: t.reservationSource || "",
        locationNumber: "",
        locationAddress: "",
        locationCity: "",
        zipCode: "",
        publicWebDescription: "",
        publicWebDescriptionEN: "",
      },
      macroGroupName: t.macroGroupName || "",
      showPics: t.showPics == "true",
      showOptionalImage: t.showOptionalImage == "true",
      showVehicleParameter: t.showVehicleParameter == "true",
      showVehicleExtraImage: t.showVehicleExtraImage == "true",
      showBookingDiscount: t.showBookingDiscount == "true",
      agreementCoupon: t.agreementCoupon || "",
      discountValueWithoutVat: t.discountValueWithoutVat || "",
      isYoungDriverAge: t.isYoungDriverAge || "",
      isYoungDriver2Age: t.isYoungDriver2Age || "",
      isSeniorDriverAge: t.isSeniorDriverAge || "",
      noFeeAge: t.noFeeAge || "",
      isAgeLimitSelected: t.isAgeLimitSelected || "",
      dropOffLocationName: t.dropOffLocationName || "",
      pickUpLocationName: t.pickUpLocationName || "",
      isPayLaterFee: false,
      payLaterFeeAmt: "",
      youngDriverAgeValue: t.youngDriverAgeValue || "",
      youngDriver2AgeValue: t.youngDriver2AgeValue || "",
      seniorDriverAgeValue: t.seniorDriverAgeValue || "",
      noAgeDriverValue: t.noAgeDriverValue || "",
      affiliateDetails: {
        name: t.affiliateName,
        code: t.affiliateId || "",
      },
    };
    const userId: any = localStorage.getItem("userID");
    if (userId != null && userId != "null" && userId != "") {
      BookingApi.getReservationLocations()
        .then((locationResponse) => {
          const dropOffLocation = locationResponse.data.find(
            (location) => location.locationCode == t.dropOffLocation
          );
          const pickUpLocation = locationResponse.data.find(
            (location) => location.locationCode == t.pickUpLocation
          );
          if (!pickUpLocation || !dropOffLocation) {
            navigation("/");
            return Promise.reject(new Error("Invalid location"));
          }
          currentBookingDetails.pickUpLocationDetails = {
            reservationSourcePayLater:
              searchParams.get("reservationSourcePayLater") === "true",
            reservationSourcePayNow:
              searchParams.get("reservationSourcePayLater") === "true",
            name: pickUpLocation?.locationName || dropOffLocation?.locationName,
            code: pickUpLocation?.locationCode || dropOffLocation?.locationCode,
            locationAddress:
              pickUpLocation?.locationAddress ||
              dropOffLocation?.locationAddress,
            locationCity:
              pickUpLocation?.locationCity || dropOffLocation?.locationCity,
            locationNumber:
              pickUpLocation?.locationNumber || dropOffLocation?.locationNumber,
            publicWebDescription:
              pickUpLocation?.publicWebDescription ||
              dropOffLocation?.publicWebDescription,
            publicWebDescriptionEN:
              pickUpLocation?.publicWebDescriptionEN ||
              dropOffLocation?.publicWebDescriptionEN,
            zipCode: pickUpLocation?.zipCode || dropOffLocation?.zipCode,
          };
          currentBookingDetails.dropOffLocationDetails = {
            reservationSourcePayLater:
              searchParams.get("reservationSourcePayLater") === "true",
            reservationSourcePayNow:
              searchParams.get("reservationSourcePayLater") === "true",
            locationAddress: dropOffLocation?.locationAddress,
            locationCity: dropOffLocation?.locationCity,
            locationNumber: dropOffLocation?.locationNumber,
            publicWebDescription: dropOffLocation?.publicWebDescription,
            publicWebDescriptionEN: dropOffLocation?.publicWebDescriptionEN,
            zipCode: dropOffLocation?.zipCode,
            name: dropOffLocation?.locationName,
            code: dropOffLocation?.locationCode,
          };
          currentBookingDetails.dropAtSameLocation =
            pickUpLocation.locationCode == dropOffLocation.locationCode;
          return BookingApi.getReservationSourceForOnlineUser(userId);
        })
        .then((reservationSourcesResponse) => {
          const reservationSource =
            reservationSourcesResponse?.data?.reservationSourceList?.find(
              (source) => {
                return source.code == t.reservationSource;
              }
            );

          if (reservationSource) {
            setResSourceFound(true);
            currentBookingDetails.reservationSource = {
              reservationSourcePayLater: reservationSource.payLater ?? false,
              reservationSourcePayNow: reservationSource.payNow ?? false,
              locationAddress: "",
              locationCity: "",
              locationNumber: "",
              publicWebDescription: "",
              publicWebDescriptionEN: "",
              zipCode: "",
              name: reservationSource["description"],
              code: reservationSource.code,
            };
          }
          dispatch(
            BOOKING_ACTIONS.setBookingInformation({
              ...currentBookingDetails,
            })
          );
          return BookingApi.getReservationVehicles(currentBookingDetails);
        })
        .then((vehiclesResponse) => {
          dispatch(
            BOOKING_ACTIONS.setSessionExpiryTime(
              addMinutes(new Date(), TimeInMinutes).getTime()
            )
          );
          setErrorMsgNoVehicle(
            vehiclesResponse.data?.data?.errors?.Error?.ShortText
          );
          setAllVehicles([...(vehiclesResponse.data?.data?.Vehicles || [])]);
        })
        .catch((error) => {
          console.error(error);
          navigation("/");
        })
        .finally(() => {
          setActiveStep(0);
          setPushState(0);
          setIsLoading(false);
        });
    } else if (!resSourceFound) {
      BookingApi.getReservationLocations()
        .then((locationResponse) => {
          const dropOffLocation = locationResponse.data.find(
            (location) => location.locationCode == t.dropOffLocation
          );
          const pickUpLocation = locationResponse.data.find(
            (location) => location.locationCode == t.pickUpLocation
          );
          if (!pickUpLocation || !dropOffLocation) {
            navigation("/");
            return Promise.reject(new Error("Invalid location"));
          }
          currentBookingDetails.pickUpLocationDetails = {
            reservationSourcePayLater:
              searchParams.get("reservationSourcePayLater") === "true",
            reservationSourcePayNow:
              searchParams.get("reservationSourcePayLater") === "true",
            name: pickUpLocation?.locationName || dropOffLocation?.locationName,
            code: pickUpLocation?.locationCode || dropOffLocation?.locationCode,
            locationAddress:
              pickUpLocation?.locationAddress ||
              dropOffLocation?.locationAddress,
            locationCity:
              pickUpLocation?.locationCity || dropOffLocation?.locationCity,
            locationNumber:
              pickUpLocation?.locationNumber || dropOffLocation?.locationNumber,
            publicWebDescription:
              pickUpLocation?.publicWebDescription ||
              dropOffLocation?.publicWebDescription,
            publicWebDescriptionEN:
              pickUpLocation?.publicWebDescriptionEN ||
              dropOffLocation?.publicWebDescriptionEN,
            zipCode: pickUpLocation?.zipCode || dropOffLocation?.zipCode,
          };
          currentBookingDetails.dropOffLocationDetails = {
            reservationSourcePayLater:
              searchParams.get("reservationSourcePayLater") === "true",
            reservationSourcePayNow:
              searchParams.get("reservationSourcePayLater") === "true",
            locationAddress: dropOffLocation?.locationAddress,
            locationCity: dropOffLocation?.locationCity,
            locationNumber: dropOffLocation?.locationNumber,
            publicWebDescription: dropOffLocation?.publicWebDescription,
            publicWebDescriptionEN: dropOffLocation?.publicWebDescriptionEN,
            zipCode: dropOffLocation?.zipCode,
            name: dropOffLocation?.locationName,
            code: dropOffLocation?.locationCode,
          };
          currentBookingDetails.dropAtSameLocation =
            pickUpLocation.locationCode == dropOffLocation.locationCode;
          return BookingApi.getAllReservationSources();
        })
        .then((reservationSourcesResponse) => {
          const reservationSource = reservationSourcesResponse?.find(
            (source) => {
              return source.Codice == t.reservationSource;
            }
          );
          if (!reservationSource) {
            navigation("/");
            return Promise.reject(new Error("Invalid reservation source"));
          }
          currentBookingDetails.reservationSource = {
            reservationSourcePayLater: reservationSource.payLater ?? false,
            reservationSourcePayNow: reservationSource.payNow ?? false,
            locationAddress: "",
            locationCity: "",
            locationNumber: "",
            publicWebDescription: "",
            publicWebDescriptionEN: "",
            zipCode: "",
            name: reservationSource["Ragione Sociale textRS"],
            code: reservationSource.Codice,
          };
          dispatch(
            BOOKING_ACTIONS.setBookingInformation({
              ...currentBookingDetails,
            })
          );
          return BookingApi.getReservationVehicles(currentBookingDetails);
        })
        .then((vehiclesResponse) => {
          dispatch(
            BOOKING_ACTIONS.setSessionExpiryTime(
              addMinutes(new Date(), TimeInMinutes).getTime()
            )
          );
          setErrorMsgNoVehicle(
            vehiclesResponse.data?.data?.errors?.Error?.ShortText
          );
          setAllVehicles([...(vehiclesResponse.data?.data?.Vehicles || [])]);
        })
        .catch((error) => {
          console.error(error);
          navigation("/");
        })
        .finally(() => {
          setActiveStep(0);
          setPushState(0);
          setIsLoading(false);
        });
    }
  }, []);

  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  const isSmallScreen = window.innerWidth <= 768;
  const isMobileView = window.innerWidth < 500;
  const isIpadMiniScreen = window.innerWidth >= 500 && window.innerWidth <= 768;
  const isDesktopScreen = window.innerWidth >= 1300;
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const InTabletScreenFilter =
    window.innerWidth >= 768 && window.innerWidth <= 1000;
  const insuranceRef = useRef(null);
  const [insuranceDivHeight, SetInsuranceDivHeight] = useState<number>(0);
  const [extrasDivHeight, SetExtrasDivHeight] = useState<number>(0);
  const [isFindMoreTriggeredInInsComp, setIsFindMoreTriggeredInInsComp] =
    useState(false);
  useEffect(() => {
    if (insuranceRef.current) {
      const insDivHeight = insuranceRef.current.offsetHeight;
      SetInsuranceDivHeight(insDivHeight);
    }
    if (selectVehicleExtrasRef.current) {
      const extrasHeight = selectVehicleExtrasRef.current.offsetHeight;
      SetExtrasDivHeight(extrasHeight);
    }
  });
  // in all rendering i m setting the insurance and extra height. I am also updating the height when we click the finoutmore button on ins card
  useEffect(() => {
    if (insuranceRef.current) {
      const insDivHeight = insuranceRef.current.offsetHeight;
      SetInsuranceDivHeight(insDivHeight);
    }
  }, [isFindMoreTriggeredInInsComp]);
  useEffect(() => {
    if (activeStep == 1 && appSettings?.showInIframe) {
      requestParentResize();
    }
  });
  function requestParentResize() {
    let iframeHeight;
    if (isSmallScreen) {
      if (selectedFields?.insurance) {
        iframeHeight = 2575;
      } else {
        iframeHeight = 4180;
      }
    } else {
      iframeHeight = insuranceDivHeight + extrasDivHeight + 112 + 184 + 80;
    }

    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }

  useEffect(() => {
    if (activeStep == 1 && appSettings?.showInIframe) {
      requestParentResize();
    }
    if (activeStep == 2) {
      window.scrollTo(0, 50);
    }
  }, [activeStep]);
  const [open, setOpen] = useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const [findInsuranceBody, setFindInsuranceBody] = useState({});
  const findTextForInsuranceBody = (feature, vehicleName) => {
    if (!findInsuranceBody[feature]) {
      const textVal = data.find(
        (item) =>
          item.key === "insurance:" + feature.toUpperCase() + ":" + vehicleName
      );
      setFindInsuranceBody((prevData) => ({
        ...prevData,
        [feature]: textVal ? textVal?.body : "",
      }));
    }
  };
  const isFilterEnable =
    (enableFilterByFuel && filterfuelTypeData.length > 0) ||
    (enableFilterBySeat && filterseatData.length > 0) ||
    (enableFilterByTransmission && filtertransmissionData.length > 0);
  /*------------------------------Handle card in mobile and table view----------------------------------------------------*/
  const [isExpanded1, setIsExpanded1] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const defaultCurrency = appSettings?.defaultCurrency;
  const handleForwardClick = () => {
    if (extrasAndInsurance) {
      setVisibleCards(visibleCards.map((index) => index + 2));
    } else {
      setVisibleCards([1, 3]);
    }
  };
  const handleBackwardClick = () => {
    if (extrasAndInsurance) {
      setVisibleCards(visibleCards.map((index) => index - 2));
    } else {
      setVisibleCards([0, 1]);
    }
  };

  const handleStepTwoActions = (vehicleDetails) => {
    if (vehicleDetails) {
      const totalPrice =
        searchParams.get("reservationSourcePayLater") === "true" ||
        (userId != null && userId != "null" && userId != "")
          ? parseFloat(
              (vehicleDetails?.Reference?.ID &&
              vehicleDetails?.Reference?.ID > 0
                ? (
                    vehicleDetails?.Reference.originalAmount -
                    vehicleDetails?.Reference.ID
                  ).toString()
                : vehicleDetails?.TotalCharge?.RateTotalAmount.toString()) ||
                "0"
            )
          : parseFloat(
              (vehicleDetails?.Reference?.ID &&
              vehicleDetails?.Reference?.ID > 0
                ? (
                    vehicleDetails?.Reference?.originalAmount -
                    vehicleDetails?.Reference.ID
                  ).toString()
                : vehicleDetails?.TotalCharge?.RateTotalAmount.toString()) ||
                "0"
            ) * discount;

      setSelectedPrice(totalPrice);
      dispatch(
        BOOKING_ACTIONS.setSelectedVehicleDetails({
          paymentDetails: {
            addedExtraOptions: selectedFields?.options,
            isDiscounted:
              searchParams.get("reservationSourcePayLater") === "true" ||
              (userId != null && userId != "null" && userId != "")
                ? 0
                : (parseFloat(
                    (selectedVehicle?.Reference?.ID &&
                    selectedVehicle?.Reference?.ID > 0
                      ? (
                          selectedVehicle?.Reference?.originalAmount -
                          selectedVehicle?.Reference.ID
                        ).toString()
                      : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) ||
                      "0"
                  ) *
                    parseFloat(selectedVehicle?.Vehicle?.payNowDis || "0")) /
                    100 || 0,
            price: totalPrice,
            selectedInsurance: selectedFields?.insurance,
            isPayLaterFee: false,
            payLaterFeeAmt: "",
            type: "",
            extrasPrice: extrasPrice,
            extrasIncluded: true,
          },
          vehicleDetails: vehicleDetails,
        })
      );

      setActiveStep(2);
      setPushState(2);
    }
  };
  const [allPayNowMethods, setAllPayNowMethods] = useState<
    Partial<AllPaymentMethodsResponse>
  >({});
  const [allPayLaterMethods, setAllPayLaterMethods] = useState<
    Partial<AllPaymentMethodsResponse>
  >({});
  const affiliateId = searchParams.get("affiliateId") || "";
  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const e = await BookingApi.getAllNewPaymentMethods(affiliateId);

        if (e?.payNow && Object.keys(e.payNow).length > 0) {
          setAllPayNowMethods(e.payNow);
        } else if (e?.allPayments) {
          setAllPayNowMethods(e.allPayments);
        } else {
          setAllPayNowMethods({});
        }

        if (e?.payLater && Object.keys(e.payLater).length > 0) {
          setAllPayLaterMethods(e.payLater);
        } else if (e?.allPayments) {
          setAllPayLaterMethods(e.allPayments);
        } else {
          setAllPayLaterMethods({});
        }
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };
    fetchPaymentMethods();
  }, [affiliateId]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const toggleExpand1 = () => {
    setIsExpanded2(false);
    setIsExpanded3(false);
    setIsExpanded4(false);
    setIsExpanded1(!isExpanded1);
  };
  const toggleExpand2 = () => {
    if (
      activeStep == 1 ||
      activeStep == 2 ||
      (isEdit == true && activeStep == 0)
    ) {
      setIsExpanded1(false);
      setIsExpanded3(false);
      setIsExpanded4(false);
      setIsExpanded2(!isExpanded2);
    }
  };
  const toggleExpand3 = () => {
    if (
      activeStep == 1 &&
      selectedFields.insurance == null &&
      Object.keys(selectedFields.options).length == 0
    ) {
      setIsExpanded1(false);
      setIsExpanded2(false);
      setIsExpanded4(false);
      setIsExpanded3(false);
      if (includedIns) {
        setIsExpanded3(!isExpanded3);
      }
    } else if (activeStep == 1 || activeStep == 2) {
      setIsExpanded1(false);
      setIsExpanded2(false);
      setIsExpanded4(false);
      setIsExpanded3(!isExpanded3);
    }
  };
  const toggleExpand4 = () => {
    if (activeStep == 2) {
      setIsExpanded1(false);
      setIsExpanded3(false);
      setIsExpanded2(false);
      setIsExpanded4(!isExpanded4);
    }
  };
  useEffect(() => {
    if (activeStep == 1 && extrasAndInsurance && !isEdit) {
      handleForwardClick();
    }
    if (activeStep == 2 && !extrasAndInsurance) {
      handleForwardClick();
    }
    if (
      activeStep == 1 &&
      selectedFields.insurance == null &&
      Object.keys(selectedFields.options).length == 0
    ) {
      setIsExpanded1(false);
      setIsExpanded2(true);
      setIsExpanded3(false);
      setIsExpanded4(false);
    }
    if (activeStep == 2 && !extrasAndInsurance) {
      setIsExpanded1(false);
      setIsExpanded2(false);
      setIsExpanded3(false);
      setIsExpanded4(true);
      handleForwardClick();
    }
    if (activeStep == 2) {
      setIsExpanded2(false);
      setIsExpanded3(false);
      setIsExpanded4(true);
    }
  }, [activeStep]);
  useEffect(() => {
    if (
      Object.keys(selectedFields.options).length > 0 ||
      selectedFields.insurance != null ||
      includedIns
    ) {
      setIsExpanded3(true);
      setIsExpanded1(false);
      setIsExpanded2(false);
      setIsExpanded4(false);
    }
  }, [selectedFields]);
  const [visibleCards, setVisibleCards] = useState([0, 1]);
  const getClassName = () => {
    let className = "flex flex-col gap-4 grow box-border w-full ";
    if (isSmallScreen) {
      if (!isFilterEnable && isExpanded1 && isIpadMiniScreen) {
        className += "mt-[60%]";
      } else if (!isFilterEnable && isExpanded1) {
        className += "mt-[100%]";
      } else if (
        !isExpanded2 &&
        !isExpanded1 &&
        !isExpanded3 &&
        !isExpanded4 &&
        !isFilterEnable
      ) {
        className += "mt-[65%]";
      } else if (
        !isFilterEnable &&
        !isExpanded1 &&
        isIpadMiniScreen &&
        activeStep == 0
      ) {
        className += "mt-[60%]";
      } else if (!isFilterEnable && !isExpanded1) {
        className += isEdit ? "mt-[100%]" : "mt-[60%]";
      } else {
        className += "mt-[2%]";
      }
    } else {
      className += "mt-[0%]";
    }

    return className;
  };
  const getMarginTop = () => {
    if (
      isExpanded2 == false &&
      isExpanded1 == false &&
      isExpanded3 == false &&
      isMobileView
    ) {
      return "50%";
    } else if (
      isIpadMiniScreen &&
      isExpanded2 == false &&
      isExpanded1 == false &&
      isExpanded3 == false
    ) {
      return "30%";
    } else if (isIpadMiniScreen) {
      return "50%";
    } else if (isMobileView) {
      return "95%";
    }
  };
  const payMarginTop = () => {
    if (
      !isExpanded2 &&
      !isExpanded1 &&
      !isExpanded3 &&
      !isExpanded4 &&
      isMobileView
    ) {
      return "50%";
    } else if (
      !isExpanded2 &&
      !isExpanded1 &&
      !isExpanded3 &&
      !isExpanded4 &&
      isIpadMiniScreen
    ) {
      return "30%";
    } else if (isIpadMiniScreen) {
      return "60%";
    } else if (isMobileView) {
      return "85%";
    }
  };
  const [includedIns, setIncludedIns] = useState("");
  useEffect(() => {
    if (insuranceList["Booking Insurance"] != null) {
      const includedInsurance = insuranceList["Booking Insurance"].filter(
        (e) => e.Amount === 0
      )[0];
      setIncludedIns(includedInsurance);
    }
  }, [insuranceList["Booking Insurance"]]);
  /*----------------------------------------------------------------------------------------------------*/
  const cards = [
    {
      label: (
        <div
          className="flex flex-col p-2 grow h-full"
          style={
            appSettings?.fillColorToCard
              ? {
                  border: `2px solid ${dynamicTheme?.palette["primary"]?.main}`,
                  background: dynamicTheme?.palette["ternaryColor"]?.main,
                  borderRadius: appSettings?.recapHeaderBorderRadius
                    ? `${appSettings?.recapHeaderBorderRadius}rem`
                    : "0px",
                }
              : {
                  borderBottom: `5px solid ${dynamicTheme?.palette["ternaryColor"]?.main}`,
                  borderRadius: appSettings?.recapHeaderBorderRadius
                    ? `${appSettings?.recapHeaderBorderRadius}rem`
                    : "0px",
                  background: recapCardBackgroundColor
                    ? recapCardBackgroundColor
                    : "#ffffff",
                }
          }
        >
          <div className="mb-2">
            <div className="flex justify-between">
              <span
                className="text-xs text-white flex items-center justify-center mt-[5px]"
                style={{
                  background: dynamicTheme?.palette["primary"]?.main,
                  width: "20px",
                  height: "20px",
                  borderRadius: "10px",
                }}
              >
                1
              </span>

              <p
                className={`text-lg text-black grow ml-[10px] whitespace-nowrap`}
                style={
                  appSettings?.fillColorToCard
                    ? {
                        color:
                          dynamicTheme?.palette["ternaryColor"].contrastText,
                      }
                    : {
                        color:
                          dynamicTheme?.palette["backgroundColor"].contrastText,
                      } && { fontWeight: "normal" }
                }
              >
                {translate("Selected Details")}
              </p>
              <button
                style={{
                  color: dynamicTheme?.palette["backgroundColor"].contrastText,
                }}
                onClick={() => {
                  if (redirectUrl != null && redirectUrl != "") {
                    window.location.href = redirectUrl;
                    hideIframe();
                  } else {
                    handleEdit();
                  }
                }}
              >
                {t("Edit")}
              </button>
            </div>
            <div>
              <div className="flex flex-row text-black justify-between">
                <div className="p-2 text-left">
                  <h6
                    className="text-sm"
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    {pickUpLabel}
                  </h6>
                  <div
                    className="pt-3"
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    <span style={{ fontSize: "large", fontWeight: "bold" }}>
                      {appSettings?.showLocationNameInSummary
                        ? bookingInformation?.pickUpLocationName
                        : bookingInformation?.pickUpLocationDetails?.code}
                    </span>
                  </div>
                  <div
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    <p style={{ fontSize: "small" }}>
                      {t("date", {
                        date: new Date(bookingInformation.pickUpDateAndTime),
                      })}{" "}
                      {new Date(bookingInformation.pickUpDateAndTime)
                        .getHours()
                        .toString()
                        .padStart(2, "0")}
                      {":"}
                      {new Date(bookingInformation.pickUpDateAndTime)
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}
                    </p>
                  </div>
                </div>
                <div className="p-2 text-left">
                  <h6
                    className="text-sm"
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                            fontWeight: "500px",
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                            fontWeight: "500px",
                          }
                    }
                  >
                    {dropOffLabel}
                  </h6>
                  <div
                    className="pt-3"
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    <span style={{ fontSize: "large", fontWeight: "bold" }}>
                      {appSettings?.showLocationNameInSummary
                        ? bookingInformation.dropOffLocationName
                        : bookingInformation.dropOffLocationDetails.code}
                    </span>
                  </div>
                  <div
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    <p style={{ fontSize: "small" }}>
                      {t("date", {
                        date: new Date(bookingInformation.dropOffDateAndTime),
                      })}{" "}
                      {new Date(bookingInformation.dropOffDateAndTime)
                        .getHours()
                        .toString()
                        .padStart(2, "0")}
                      {":"}
                      {new Date(bookingInformation.dropOffDateAndTime)
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}
                    </p>
                  </div>
                </div>
              </div>
              {searchParams.get("agreementCoupon") && (
                <div style={{ color: "red", paddingLeft: "9px" }}>
                  <p>Codice promo: {searchParams.get("agreementCoupon")}</p>
                </div>
              )}
            </div>

            {(searchParams.get("reservationSourcePayNow") === "true" ||
              searchParams.get("reservationSourcePayLater") === "true") && (
              <div
                className="flex "
                style={
                  appSettings?.fillColorToCard
                    ? {
                        color:
                          dynamicTheme?.palette["ternaryColor"].contrastText,
                      }
                    : {
                        color:
                          dynamicTheme?.palette["backgroundColor"].contrastText,
                      }
                }
              >
                <span style={{ color: "red", marginLeft: "10px" }}>
                  {" "}
                  {translate("Mode")}
                </span>
                {searchParams.get("reservationSourcePayNow") === "true" && (
                  <span style={{ color: "red", marginLeft: "10px" }}>
                    {" "}
                    {translate("Pay Now")}
                  </span>
                )}
                {searchParams.get("reservationSourcePayLater") === "true" && (
                  <span style={{ color: "red", marginLeft: "10px" }}>
                    {" "}
                    {translate("Pay Later")}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      label: (
        <div
          className="flex flex-col p-2 grow h-full"
          style={
            appSettings?.fillColorToCard
              ? {
                  border: `2px solid ${dynamicTheme?.palette["primary"].main}`,
                  borderRadius: appSettings?.recapHeaderBorderRadius
                    ? `${appSettings?.recapHeaderBorderRadius}rem`
                    : "0px",
                  background:
                    activeStep > 0
                      ? dynamicTheme?.palette["ternaryColor"].main
                      : "inherit",
                }
              : {
                  borderLeft: `1px solid #cccccc`,
                  borderRadius: appSettings?.recapHeaderBorderRadius
                    ? `${appSettings?.recapHeaderBorderRadius}rem`
                    : "0px",
                  borderBottom:
                    activeStep > 0
                      ? `4px solid ${dynamicTheme?.palette["ternaryColor"].main}`
                      : `4px solid #cccccc`,
                  borderTop: "none",
                  background: recapCardBackgroundColor
                    ? recapCardBackgroundColor
                    : "#ffffff",
                }
          }
        >
          <div className="flex justify-between">
            <span
              className="text-xs text-white flex items-center justify-center mt-[5px]"
              style={{
                background: dynamicTheme?.palette["primary"]?.main,
                width: "20px",
                height: "20px",
                borderRadius: "10px",
              }}
            >
              2
            </span>
            <p
              className={`text-lg text-black grow ml-[10px]`}
              style={
                {
                  color:
                    activeStep > 0 && appSettings?.fillColorToCard
                      ? dynamicTheme?.palette["ternaryColor"].contrastText
                      : dynamicTheme?.palette["backgroundColor"].contrastText,
                } && activeStep == 0
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              {t("Car Selection")}
            </p>
            <button
              style={{
                color: dynamicTheme?.palette["backgroundColor"].contrastText,
              }}
              onClick={() => {
                if (activeStep > 0) {
                  setActiveStep(0);
                  if (activeStep == 2) {
                    window.history.pushState({ step: 2 }, "Step 2", "");
                  }
                  setIsEdit(false);
                }
              }}
            >
              {t("Edit")}
            </button>
          </div>
          {selectedVehicle ? (
            <div className="flex flex-row text-black justify-between">
              <div className="p-2 text-left">
                <h4
                  className="text-md font-bold"
                  style={{
                    color:
                      activeStep > 0 && appSettings?.fillColorToCard
                        ? dynamicTheme?.palette["ternaryColor"].contrastText
                        : dynamicTheme?.palette["backgroundColor"].contrastText,
                  }}
                >
                  {t("Vehicle")}
                </h4>
                <div
                  className="pt-1"
                  style={{
                    fontSize: "small",
                    color:
                      activeStep > 0 && appSettings?.fillColorToCard
                        ? dynamicTheme?.palette["ternaryColor"].contrastText
                        : dynamicTheme?.palette["backgroundColor"].contrastText,
                  }}
                >
                  {/* showVehicleGroupDescInRecap is used to show the vehicle description in recap bar */}
                  {appSettings?.showVehicleGroupDescInRecap ? (
                    <>
                      <span>
                        Gruppo {selectedVehicle.Vehicle?.VendorCarType}
                      </span>
                      <br />
                      <span>
                        {selectedVehicle.Vehicle?.groupWebDescription}
                      </span>
                    </>
                  ) : (
                    <>
                      {selectedVehicle.Vehicle?.VehMakeModel?.Name}
                      <span>{selectedVehicle.Vehicle?.vehicelExtraImage}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="p-2 text-left">
                <h4 className="text-md text-[primary] font-bold"></h4>
                <div className="pt-1">
                  <span className="pt-1 pb-1 mr-1">
                    <img
                      src={
                        (selectedVehicle.Vehicle?.vehicleGroupPic ||
                          selectedVehicle.Vehicle?.vehicelExtraImage) as string
                      }
                      className="md:min-w-full aspect-video object-contain h-24"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      disabled: !selectedVehicle,
      label: (
        <>
          <section
            className="flex flex-col h-full w-full grow p-2 grow h-full"
            style={
              appSettings?.fillColorToCard
                ? {
                    border: `2px solid ${dynamicTheme?.palette["primary"].main}`,
                    borderRadius: appSettings?.recapHeaderBorderRadius
                      ? `${appSettings?.recapHeaderBorderRadius}rem`
                      : "0px",
                    background:
                      activeStep > 1
                        ? dynamicTheme?.palette["ternaryColor"].main
                        : "inherit",
                  }
                : {
                    borderLeft: `1px solid #cccccc`,
                    borderRadius: appSettings?.recapHeaderBorderRadius
                      ? `${appSettings?.recapHeaderBorderRadius}rem`
                      : "0px",
                    borderBottom:
                      activeStep > 1
                        ? `4px solid ${dynamicTheme?.palette["ternaryColor"].main}`
                        : `4px solid #cccccc`,
                    borderTop: "none",
                    background: recapCardBackgroundColor
                      ? recapCardBackgroundColor
                      : "#ffffff",
                  }
            }
          >
            <div className="flex flex-row">
              <span
                className="text-xs text-white flex items-center justify-center mt-[5px]"
                style={{
                  background: dynamicTheme?.palette["primary"]?.main,
                  width: "20px",
                  height: "20px",
                  borderRadius: "10px",
                }}
              >
                3
              </span>
              <p
                className="text-lg text-black grow ml-[10px]"
                style={
                  {
                    color:
                      activeStep > 1 && appSettings?.fillColorToCard
                        ? dynamicTheme?.palette["ternaryColor"].contrastText
                        : dynamicTheme?.palette["backgroundColor"].contrastText,
                  } && activeStep == 1
                    ? { fontWeight: "bold" }
                    : { fontWeight: "normal" }
                }
              >
                Extra
              </p>
              <button
                className="h-fit"
                style={{
                  color: dynamicTheme?.palette["backgroundColor"].contrastText,
                }}
                onClick={() => {
                  if (activeStep > 1) {
                    setActiveStep(1);
                    setPushState(1);
                    if (istabletScreen) {
                      setIsEdit(true);
                    }
                  }
                }}
              >
                {t("Edit")}
              </button>
            </div>
            <div
              className={`flex flex-row text-black whitespace-nowrap ${
                Object.keys(selectedFields.options).length > 0 &&
                selectedFields.insurance &&
                isDesktopScreen
                  ? "justify-around space-x-6"
                  : ""
              }`}
              style={{
                width: "100%",
                fontStyle: fontFamilyStyle,
              }}
            >
              {/* Left Section: Coperture Include and Insurance Added */}
              {includedIns ||
              selectedFields.insurance ||
              Object.values(selectedFields.options).length > 0 ? (
                <div
                  className="p-1 text-left grow"
                  style={{
                    width: "50%",
                  }}
                >
                  {includedIns && (
                    <div
                      style={{
                        padding: "0.25rem",
                        fontStyle: fontFamilyStyle,
                        lineHeight: "initial",
                      }}
                    >
                      <h4
                        className="text-md font-bold"
                        style={{
                          color:
                            activeStep > 1 && appSettings?.fillColorToCard
                              ? dynamicTheme?.palette["ternaryColor"]
                                  .contrastText
                              : dynamicTheme?.palette["backgroundColor"]
                                  .contrastText,
                          fontSize: "12px",
                        }}
                      >
                        {translate("Included insurance")}
                      </h4>
                      <span
                        className="pt-1 pb-1"
                        style={{ fontSize: "10px", color: "black" }}
                      >
                        {includedIns.Description}
                      </span>
                    </div>
                  )}
                  {selectedFields.insurance && (
                    <div
                      style={{
                        padding: "0.25rem",
                        lineHeight: "initial",
                      }}
                    >
                      <h4
                        className="text-md font-bold"
                        style={{
                          color:
                            activeStep > 1 && appSettings?.fillColorToCard
                              ? dynamicTheme?.palette["ternaryColor"]
                                  .contrastText
                              : dynamicTheme?.palette["backgroundColor"]
                                  .contrastText,
                          fontSize: "12px",
                        }}
                      >
                        {t("Insurance Added")}
                      </h4>
                      <div
                        className="pt-1 whitespace-nowrap flex-row"
                        style={{
                          gap: "5px",
                          color:
                            activeStep > 1 && appSettings?.fillColorToCard
                              ? dynamicTheme?.palette["ternaryColor"]
                                  .contrastText
                              : dynamicTheme?.palette["backgroundColor"]
                                  .contrastText,
                          fontSize: "10px",
                        }}
                      >
                        <span className="pt-1 pb-1">
                          {selectedFields.insurance?.details}
                        </span>
                        <span>{selectedFields.insurance?.name}</span>
                        <span
                          className="whitespace-nowrap"
                          style={{
                            fontWeight: "bold",
                            paddingLeft: "10px",
                          }}
                        >
                          {defaultCurrency || "€"}
                          {selectedFields.insurance?.price}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <span></span>
              )}

              {/* Right Section: Added Extras */}
              {Object.keys(selectedFields.options).length > 0 && (
                <div
                  className="p-1 text-left"
                  style={{
                    width: "50%",
                    paddingLeft: "24px",
                  }}
                >
                  <h4
                    className="text-md font-bold"
                    style={{
                      color:
                        activeStep > 1 && appSettings?.fillColorToCard
                          ? dynamicTheme?.palette["ternaryColor"].contrastText
                          : dynamicTheme?.palette["backgroundColor"]
                              .contrastText,
                      fontSize: "12px",
                    }}
                  >
                    {t("Added Extras")}
                  </h4>
                  <div
                    className="pt-1"
                    style={{
                      color:
                        activeStep > 1 && appSettings?.fillColorToCard
                          ? dynamicTheme?.palette["ternaryColor"].contrastText
                          : dynamicTheme?.palette["backgroundColor"]
                              .contrastText,
                      fontSize: "10px",
                    }}
                  >
                    {Object.entries(selectedFields.options).map(
                      ([key, value]) => (
                        <div key={key} className="flex flex-row">
                          <span className="marquee fixed-width-container">
                            {(() => {
                              const text = appSettings?.showDescFromBookingText
                                ? data.find((item) => item.key === value?.name)
                                    ?.description || value?.name
                                : appSettings?.showOptionalDescription
                                ? value?.details
                                : value?.name;

                              return text?.length < 20 ? (
                                <span className="flex flex-row fixed-width-text">
                                  {text} {"   "}
                                </span>
                              ) : (
                                <span className="marquee-container">
                                  <div className="marquee-content">
                                    <span className="marquee-item">
                                      {text} {"   "}
                                    </span>
                                    <span className="marquee-item">
                                      {text} {"   "}
                                    </span>
                                  </div>
                                </span>
                              );
                            })()}
                          </span>
                          <span
                            className="whitespace-nowrap flex flex-row font-bold ml-2"
                            style={{ fontWeight: "bold" }}
                          >
                            {!value.doNotShowOptionalPrice && (
                              <>
                                {defaultCurrency || "€"}
                                {value?.price * value?.quantity}
                              </>
                            )}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      ),
    },

    {
      label: (
        <section
          className="flex flex-col h-full w-full grow p-2 grow h-full"
          style={
            appSettings?.fillColorToCard
              ? {
                  border: `2px solid ${dynamicTheme?.palette["primary"]?.main}`,
                  background: "none",
                  borderRadius: appSettings?.recapHeaderBorderRadius
                    ? `${appSettings?.recapHeaderBorderRadius}rem`
                    : "0px",
                }
              : {
                  borderLeft: `1px solid #cccccc`,
                  borderBottom: `4px solid #cccccc`,
                  borderTop: "none",
                  borderRadius: appSettings?.recapHeaderBorderRadius
                    ? `${appSettings?.recapHeaderBorderRadius}rem`
                    : "0px",
                  background: recapCardBackgroundColor
                    ? recapCardBackgroundColor
                    : "#ffffff",
                }
          }
        >
          <div className="flex flex-row">
            <span
              className="text-xs text-white flex items-center justify-center mt-[5px]"
              style={{
                background: dynamicTheme?.palette["primary"]?.main,
                width: "20px",
                height: "20px",
                borderRadius: "10px",
              }}
            >
              {extrasAndInsurance ? "4" : "3"}
            </span>

            <p
              className="text-lg text-black ml-[10px]"
              style={
                {
                  color:
                    activeStep > 2
                      ? dynamicTheme?.palette["backgroundColor"]?.contrastText
                      : dynamicTheme?.palette["backgroundColor"]?.contrastText,
                } && activeStep == 2
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              {translate("Total Rental")}
            </p>
          </div>
          {activeStep > 0 ? (
            <div className="text-black">
              <div className="p-1 flex flex-col space-y-4">
                {extrasAndInsurance && (
                  <>
                    <div
                      className="flex justify-between"
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      <span className="font-semibold">
                        {" "}
                        {translate("Totale Noleggio:")}
                      </span>
                      <span>
                        {defaultCurrency || "€"}
                        {vehiclePrice?.toFixed(2) || 0}
                      </span>
                    </div>
                    <div
                      className="flex justify-between"
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      <span className="font-semibold">
                        {" "}
                        {translate("Totale Coperture:")}
                      </span>
                      <span>
                        {" "}
                        {defaultCurrency || "€"}
                        {insurancePrice?.toFixed(2) || 0}
                      </span>
                    </div>
                    <div
                      className="flex justify-between"
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      <span className="font-semibold">
                        {translate("Totale extras:")}
                      </span>
                      <span>
                        {" "}
                        {defaultCurrency || "€"}
                        {onlyExtrasPrice?.toFixed(2) || 0}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="p-4 flex justify-between font-bold p-1">
                <span>{translate("Totale")}</span>
                <span>
                  {" "}
                  {defaultCurrency || "€"}
                  {totalPrice?.toFixed(2) || 0}
                </span>
              </div>
            </div>
          ) : (
            <span></span>
          )}
        </section>
      ),
    },
  ];
  /*------------------------------------------------------------------------------------------------------*/

  return (
    <ThemeProvider theme={dynamicTheme || theme}>
      <div
        className="flex flex-col box-border sm:px-6 gap-4 w-full"
        style={{
          background: secondPageBackgroundColor
            ? secondPageBackgroundColor
            : "#ffffff",
          minHeight: isDesktopScreen ? "728px" : isSmallScreen ? "800px" : "",
        }}
      >
        {isFilterEnable &&
          (isSmallScreen || InTabletScreenFilter) &&
          activeStep == 0 && (
            <div
              style={{
                marginLeft: "90%",
                marginTop:
                  isSmallScreen &&
                  isExpanded1 == false &&
                  isExpanded2 == false &&
                  isExpanded3 == false &&
                  isExpanded4 == false
                    ? "60%"
                    : isExpanded1 && isSmallScreen
                    ? "100%"
                    : InTabletScreenFilter
                    ? "0%"
                    : activeStep == 0 && !isExpanded1 && isSmallScreen
                    ? "100%"
                    : "60%",
              }}
            >
              <FilterAlt
                style={{
                  width: "100%",
                  stroke: "black",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(!open)}
              />
            </div>
          )}
        {reservedArea && <CollapsibleNavbar callFrom="showVehicle" />}
        {/*------------------------------------------------------tablet screen ui for card-----------------------------------------------------*/}
        {istabletScreen && (
          <>
            <div
              className="box-border box-border py-4 flex flex-row hidden md:flex gap-1 sticky top-0 z-[10] w-full"
              style={{
                background: secondPageBackgroundColor
                  ? secondPageBackgroundColor
                  : "#ffffff",
              }}
            >
              <div className="box-border py-4 flex flex-row hidden md:flex gap-1 sticky top-0 z-[10] bg-[#FFFFFF] w-full">
                <button
                  style={{
                    color: visibleCards[0] === 0 ? "grey" : "black",
                  }}
                  onClick={handleBackwardClick}
                  disabled={visibleCards[0] === 0}
                >
                  <ArrowBackIosNewIcon />
                </button>
                {cards.map((e, index) => {
                  if (index === 2 && !extrasAndInsurance) {
                    return null;
                  }
                  if (visibleCards.includes(index) && index !== null) {
                    return (
                      <div key={index} className="w-1/2">
                        {e.label}
                      </div>
                    );
                  }
                  return null;
                })}
                <button
                  style={{
                    color:
                      visibleCards[1] === cards.length - 1 ||
                      selectedVehicle == undefined ||
                      activeStep != 2
                        ? "grey"
                        : "black",
                  }}
                  onClick={handleForwardClick}
                  disabled={
                    visibleCards[1] === cards.length - 1 ||
                    selectedVehicle == undefined ||
                    activeStep != 2
                  }
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
          </>
        )}
        {/*-------------------------------------------mobile screen ui for card-------------------------------------------------------------------------------*/}
        {isSmallScreen && (
          <div
            className={`${
              appSettings?.isRecapScrollableForMobile
                ? "absolute z-50"
                : "fixed z-50"
            } top-0 left-0 right-0 bg-white shadow-lg `}
          >
            <div
              className="flex flex-col p-2 grow h-full mt-5 bg-[#F4F4F4]"
              style={
                appSettings?.fillColorToCard
                  ? {
                      border: `2px solid ${dynamicTheme?.palette["primary"]?.main}`,
                      background: dynamicTheme?.palette["ternaryColor"]?.main,
                      borderRadius: appSettings?.recapHeaderBorderRadius
                        ? `${appSettings?.recapHeaderBorderRadius}rem`
                        : "0px",
                    }
                  : {
                      borderBottom: `5px solid ${dynamicTheme?.palette["ternaryColor"]?.main}`,
                      borderRadius: appSettings?.recapHeaderBorderRadius
                        ? `${appSettings?.recapHeaderBorderRadius}rem`
                        : "0px",
                    }
              }
            >
              <div className="mb-2">
                <div className="flex justify-between">
                  <span
                    className="text-xs text-white flex items-center justify-center mt-[5px]"
                    style={{
                      background: dynamicTheme?.palette["primary"]?.main,
                      width: "20px",
                      height: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    1
                  </span>
                  <p
                    className="text-lg text-black grow ml-[15px]"
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    {translate("Selected Details")}
                  </p>
                  <button style={{ color: "black" }} onClick={toggleExpand1}>
                    {isExpanded1 ? <ExpandLess /> : <ExpandMore />}
                  </button>
                </div>

                {isExpanded1 && (
                  <>
                    <div>
                      <button
                        className="float-right"
                        style={{
                          color:
                            dynamicTheme?.palette["backgroundColor"]
                              .contrastText,
                          fontSize: "small",
                        }}
                        onClick={() => {
                          if (redirectUrl != null && redirectUrl !== "") {
                            window.location.href = redirectUrl;
                            hideIframe();
                          } else {
                            handleEdit();
                          }
                        }}
                      >
                        {t("Edit")}
                      </button>
                    </div>
                    <div>
                      <div className="flex justify-between items-start text-black mt-4">
                        <div className="flex-1 pt-2 pl-2">
                          <h6
                            className="text-sm whitespace-nowrap"
                            style={
                              appSettings?.fillColorToCard
                                ? {
                                    color:
                                      dynamicTheme?.palette["ternaryColor"]
                                        .contrastText,
                                  }
                                : {
                                    color:
                                      dynamicTheme?.palette["backgroundColor"]
                                        .contrastText,
                                  }
                            }
                          >
                            {pickUpLabel}
                          </h6>
                          <div
                            className="pt-3"
                            style={
                              appSettings?.fillColorToCard
                                ? {
                                    color:
                                      dynamicTheme?.palette["ternaryColor"]
                                        .contrastText,
                                  }
                                : {
                                    color:
                                      dynamicTheme?.palette["backgroundColor"]
                                        .contrastText,
                                  }
                            }
                          >
                            <span
                              style={{ fontSize: "large", fontWeight: "bold" }}
                            >
                              {bookingInformation.pickUpLocationName}
                            </span>
                          </div>
                          <div
                            className="whitespace-nowrap"
                            style={
                              appSettings?.fillColorToCard
                                ? {
                                    color:
                                      dynamicTheme?.palette["ternaryColor"]
                                        .contrastText,
                                  }
                                : {
                                    color:
                                      dynamicTheme?.palette["backgroundColor"]
                                        .contrastText,
                                  }
                            }
                          >
                            <p style={{ fontSize: "small" }}>
                              {t("date", {
                                date: new Date(
                                  bookingInformation.pickUpDateAndTime
                                ),
                              })}{" "}
                              {new Date(bookingInformation.pickUpDateAndTime)
                                .getHours()
                                .toString()
                                .padStart(2, "0")}
                              :
                              {new Date(bookingInformation.pickUpDateAndTime)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}
                            </p>
                          </div>
                        </div>

                        <div className="flex-1 pl-16 pt-2">
                          <h6
                            className="text-sm whitespace-nowrap"
                            style={
                              appSettings?.fillColorToCard
                                ? {
                                    color:
                                      dynamicTheme?.palette["ternaryColor"]
                                        .contrastText,
                                  }
                                : {
                                    color:
                                      dynamicTheme?.palette["backgroundColor"]
                                        .contrastText,
                                  }
                            }
                          >
                            {dropOffLabel}
                          </h6>
                          <div
                            className="pt-3"
                            style={
                              appSettings?.fillColorToCard
                                ? {
                                    color:
                                      dynamicTheme?.palette["ternaryColor"]
                                        .contrastText,
                                  }
                                : {
                                    color:
                                      dynamicTheme?.palette["backgroundColor"]
                                        .contrastText,
                                  }
                            }
                          >
                            <span
                              style={{ fontSize: "large", fontWeight: "bold" }}
                            >
                              {bookingInformation.dropOffLocationName}
                            </span>
                          </div>
                          <div
                            className="whitespace-nowrap"
                            style={
                              appSettings?.fillColorToCard
                                ? {
                                    color:
                                      dynamicTheme?.palette["ternaryColor"]
                                        .contrastText,
                                  }
                                : {
                                    color:
                                      dynamicTheme?.palette["backgroundColor"]
                                        .contrastText,
                                  }
                            }
                          >
                            <p style={{ fontSize: "small" }}>
                              {t("date", {
                                date: new Date(
                                  bookingInformation.dropOffDateAndTime
                                ),
                              })}{" "}
                              {new Date(bookingInformation.dropOffDateAndTime)
                                .getHours()
                                .toString()
                                .padStart(2, "0")}
                              :
                              {new Date(bookingInformation.dropOffDateAndTime)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        {searchParams.get("agreementCoupon") && (
                          <div style={{ color: "red", paddingLeft: "9px" }}>
                            <p>
                              Codice promo:{" "}
                              {searchParams.get("agreementCoupon")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {(searchParams.get("reservationSourcePayNow") === "true" ||
                  searchParams.get("reservationSourcePayLater") === "true") && (
                  <div
                    className="flex "
                    style={
                      appSettings?.fillColorToCard
                        ? {
                            color:
                              dynamicTheme?.palette["ternaryColor"]
                                .contrastText,
                          }
                        : {
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }
                    }
                  >
                    <span className="pl-2" style={{ color: "red" }}>
                      {" "}
                      {translate("Mode")}
                    </span>
                    {"  "}
                    {searchParams.get("reservationSourcePayNow") === "true" && (
                      <span style={{ color: "red", marginLeft: "10px" }}>
                        {" "}
                        {translate("Pay Now")}
                      </span>
                    )}
                    {"  "}
                    {searchParams.get("reservationSourcePayLater") ===
                      "true" && (
                      <span style={{ color: "red", marginLeft: "10px" }}>
                        {" "}
                        {translate("Pay Later")}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              className="flex flex-col p-2 grow h-full"
              style={
                appSettings?.fillColorToCard
                  ? {
                      border: `2px solid ${dynamicTheme?.palette["primary"].main}`,
                      borderRadius: appSettings?.recapHeaderBorderRadius
                        ? `${appSettings?.recapHeaderBorderRadius}rem`
                        : "0px",
                      background:
                        activeStep > 0
                          ? dynamicTheme?.palette["ternaryColor"].main
                          : "inherit",
                    }
                  : {
                      border: `1px solid #cccccc`,
                      borderRadius: appSettings?.recapHeaderBorderRadius
                        ? `${appSettings?.recapHeaderBorderRadius}rem`
                        : "0px",
                      borderBottom:
                        activeStep > 0
                          ? `4px solid ${dynamicTheme?.palette["ternaryColor"].main}`
                          : `4px solid #cccccc`,
                      borderTop: "none",
                      background: recapCardBackgroundColor
                        ? recapCardBackgroundColor
                        : "#ffffff",
                    }
              }
            >
              <div className="flex justify-between">
                <span
                  className="text-xs text-white flex items-center justify-center mt-[5px]"
                  style={{
                    background: dynamicTheme?.palette["primary"]?.main,
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                >
                  2
                </span>
                <p
                  className="text-lg text-black grow ml-[15px]"
                  style={
                    {
                      color:
                        activeStep > 0 && appSettings?.fillColorToCard
                          ? dynamicTheme?.palette["ternaryColor"].contrastText
                          : dynamicTheme?.palette["backgroundColor"]
                              .contrastText,
                    } && activeStep == 0
                      ? { fontWeight: "bold" }
                      : { fontWeight: "normal" }
                  }
                >
                  {t("Car Selection")}
                </p>
                <button style={{ color: "black" }} onClick={toggleExpand2}>
                  {isExpanded2 ? <ExpandLess /> : <ExpandMore />}
                </button>
              </div>
              {selectedVehicle && isExpanded2 ? (
                <div>
                  <button
                    className={"float-right"}
                    style={{
                      color:
                        dynamicTheme?.palette["backgroundColor"].contrastText,
                    }}
                    onClick={() => {
                      if (activeStep > 0) {
                        if (activeStep == 2) {
                          window.history.pushState({ step: 2 }, "Step 2", "");
                        }
                        setActiveStep(0);
                      }
                      setIsEdit(true);
                    }}
                  >
                    {t("Edit")}
                  </button>
                  <div className="flex flex-row text-black">
                    <div className="p-2 text-left">
                      <h4
                        className="text-md font-bold"
                        style={{
                          color:
                            activeStep > 0 && appSettings?.fillColorToCard
                              ? dynamicTheme?.palette["ternaryColor"]
                                  .contrastText
                              : dynamicTheme?.palette["backgroundColor"]
                                  .contrastText,
                        }}
                      >
                        {t("Vehicle")}
                      </h4>
                      <div
                        className="pt-1"
                        style={{
                          fontSize: "small",
                          color:
                            activeStep > 0 && appSettings?.fillColorToCard
                              ? dynamicTheme?.palette["ternaryColor"]
                                  .contrastText
                              : dynamicTheme?.palette["backgroundColor"]
                                  .contrastText,
                        }}
                      >
                        {selectedVehicle.Vehicle?.VehMakeModel?.Name}
                        <span>
                          {selectedVehicle.Vehicle?.vehicelExtraImage}
                        </span>
                      </div>
                    </div>
                    <div className="p-2 text-left">
                      <h4 className="text-md text-[primary] font-bold"></h4>
                      <div className="pt-1">
                        <span className="pt-1 pb-1 mr-1">
                          <img
                            src={
                              (selectedVehicle.Vehicle?.vehicleGroupPic ||
                                selectedVehicle.Vehicle
                                  ?.vehicelExtraImage) as string
                            }
                            className="md:min-w-full aspect-video object-contain h-24"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {extrasAndInsurance && (
              <section
                className="flex flex-col h-full w-full grow p-2 grow h-full"
                style={
                  appSettings?.fillColorToCard
                    ? {
                        border: `2px solid ${dynamicTheme?.palette["primary"].main}`,
                        borderRadius: appSettings?.recapHeaderBorderRadius
                          ? `${appSettings?.recapHeaderBorderRadius}rem`
                          : "0px",
                        background:
                          activeStep > 1
                            ? dynamicTheme?.palette["ternaryColor"].main
                            : "inherit",
                      }
                    : {
                        borderRadius: appSettings?.recapHeaderBorderRadius
                          ? `${appSettings?.recapHeaderBorderRadius}rem`
                          : "0px",
                        borderBottom:
                          activeStep > 1
                            ? `4px solid ${dynamicTheme?.palette["ternaryColor"].main}`
                            : `4px solid #cccccc`,
                        borderTop: "none",
                        background: recapCardBackgroundColor
                          ? recapCardBackgroundColor
                          : "#ffffff",
                      }
                }
              >
                <div className="flex flex-row grow">
                  <span
                    className="text-xs text-white flex items-center justify-center mt-[5px]"
                    style={{
                      background: dynamicTheme?.palette["primary"]?.main,
                      width: "20px",
                      height: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    3
                  </span>
                  <p
                    className="text-lg text-black grow ml-[15px]"
                    style={
                      {
                        color:
                          activeStep > 1 && appSettings?.fillColorToCard
                            ? dynamicTheme?.palette["ternaryColor"].contrastText
                            : dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                      } && activeStep == 1
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                  >
                    Extra
                  </p>
                  <button style={{ color: "black" }} onClick={toggleExpand3}>
                    {isExpanded3 ? <ExpandLess /> : <ExpandMore />}
                  </button>
                </div>
                {(selectedFields.insurance ||
                  includedIns ||
                  Object.values(selectedFields.options).length) &&
                isExpanded3 ? (
                  <div
                    style={{
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    <button
                      className="h-fit float-right"
                      style={{
                        color:
                          dynamicTheme?.palette["backgroundColor"].contrastText,
                      }}
                      onClick={() => {
                        if (activeStep > 1) {
                          setActiveStep(1);
                          setPushState(1);
                        }
                      }}
                    >
                      {t("Edit")}
                    </button>
                    <div className="flex flex-row text-black mb-[15%]">
                      {(selectedFields.insurance || includedIns) && (
                        <div>
                          <div className="p-4" style={{ paddingBottom: "0px" }}>
                            {includedIns && (
                              <div
                                style={{
                                  fontStyle: fontFamilyStyle,
                                }}
                              >
                                <h4
                                  className="text-md font-bold"
                                  style={{
                                    color:
                                      activeStep > 1 &&
                                      appSettings?.fillColorToCard
                                        ? dynamicTheme?.palette["ternaryColor"]
                                            .contrastText
                                        : dynamicTheme?.palette[
                                            "backgroundColor"
                                          ].contrastText,
                                  }}
                                >
                                  {translate("Included insurance")}
                                </h4>
                                <span
                                  className="pt-1 pb-1"
                                  style={{ fontSize: "small", color: "black" }}
                                >
                                  {includedIns.Description}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="p-4 text-left whitespace-nowrap w-[50%]">
                            {selectedFields.insurance && (
                              <>
                                <h4
                                  className="text-md font-bold"
                                  style={{
                                    color:
                                      activeStep > 1 &&
                                      appSettings?.fillColorToCard
                                        ? dynamicTheme?.palette["ternaryColor"]
                                            .contrastText
                                        : dynamicTheme?.palette[
                                            "backgroundColor"
                                          ].contrastText,
                                  }}
                                >
                                  {t("Insurance Added")}
                                </h4>
                                <div
                                  className="pt-1"
                                  style={{
                                    color:
                                      activeStep > 1 &&
                                      appSettings?.fillColorToCard
                                        ? dynamicTheme?.palette["ternaryColor"]
                                            .contrastText
                                        : dynamicTheme?.palette[
                                            "backgroundColor"
                                          ].contrastText,
                                  }}
                                >
                                  <span
                                    className="pt-1 pb-1"
                                    style={{ fontSize: "small" }}
                                  >
                                    {selectedFields.insurance?.details}
                                  </span>
                                  <span style={{ fontSize: "small" }}>
                                    {selectedFields.insurance?.name}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    color:
                                      activeStep > 1 &&
                                      appSettings?.fillColorToCard
                                        ? dynamicTheme?.palette["ternaryColor"]
                                            .contrastText
                                        : dynamicTheme?.palette[
                                            "backgroundColor"
                                          ].contrastText,
                                  }}
                                >
                                  <span style={{ fontSize: "small" }}>
                                    {defaultCurrency || "€"}
                                    {selectedFields.insurance?.price}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {Object.keys(selectedFields.options).length > 0 && (
                        <div
                          className="p-4 text-left whitespace-nowrap mt-2 w-[50%]"
                          style={{
                            marginLeft: selectedFields.insurance ? "5%" : "",
                          }}
                        >
                          <h4
                            className="text-md font-bold"
                            style={{
                              color:
                                activeStep > 1 && appSettings?.fillColorToCard
                                  ? dynamicTheme?.palette["ternaryColor"]
                                      .contrastText
                                  : dynamicTheme?.palette["backgroundColor"]
                                      .contrastText, // Set color based on condition
                            }}
                          >
                            {t("Added Extras")}
                          </h4>
                          <div
                            className="pt-1"
                            style={{
                              color:
                                activeStep > 1 && appSettings?.fillColorToCard
                                  ? dynamicTheme?.palette["ternaryColor"]
                                      .contrastText
                                  : dynamicTheme?.palette["backgroundColor"]
                                      .contrastText,
                            }}
                          >
                            <span
                              className="pt-1 pb-1"
                              style={{ fontSize: "small" }}
                            >
                              {Object.entries(selectedFields.options).map(
                                ([key, value]) => (
                                  <div key={key} className="flex flex-row">
                                    {(() => {
                                      const text =
                                        appSettings?.showDescFromBookingText
                                          ? data.find(
                                              (item) => item.key === value?.name
                                            )?.description || value?.name
                                          : appSettings?.showOptionalDescription
                                          ? value?.details
                                          : value?.name;

                                      return !isSmallScreen ? (
                                        text?.length <= 20
                                      ) : text?.length <= 10 ? (
                                        <span className="flex flex-row w-[130%] md:w-[50%]">
                                          {text} {"   "}
                                        </span>
                                      ) : (
                                        <span className="marquee-container">
                                          <div className="marquee-content">
                                            <span className="marquee-item">
                                              {text} {"   "}
                                            </span>
                                            <span className="marquee-item">
                                              {text} {"   "}
                                            </span>
                                          </div>
                                        </span>
                                      );
                                    })()}
                                    <span
                                      className="whitespace-nowrap flex flex-row font-bold ml-[10%]"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {!value.doNotShowOptionalPrice && (
                                        <>
                                          {defaultCurrency || "€"}
                                          {value?.price * value?.quantity}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                )
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <span>
                    {(Object.keys(selectedFields.options).length == 0 ||
                      selectedFields.insurance == null) &&
                      isExpanded3 &&
                      activeStep == 2 && (
                        <button
                          className="h-fit float-right"
                          style={{
                            color:
                              dynamicTheme?.palette["backgroundColor"]
                                .contrastText,
                          }}
                          onClick={() => {
                            if (activeStep > 1) {
                              setActiveStep(1);
                              setPushState(1);
                            }
                          }}
                        >
                          {t("Edit")}
                        </button>
                      )}
                  </span>
                )}
              </section>
            )}
            <section
              className="flex flex-col h-full w-full grow p-2 grow h-full"
              style={
                appSettings?.fillColorToCard
                  ? {
                      border: `2px solid ${dynamicTheme?.palette["primary"]?.main}`,
                      background: "none",
                      borderRadius: appSettings?.recapHeaderBorderRadius
                        ? `${appSettings?.recapHeaderBorderRadius}rem`
                        : "0px",
                    }
                  : {
                      border: `1px solid #cccccc`,
                      borderBottom: `4px solid #cccccc`,
                      borderTop: "none",
                      borderRadius: appSettings?.recapHeaderBorderRadius
                        ? `${appSettings?.recapHeaderBorderRadius}rem`
                        : "0px",
                      background: recapCardBackgroundColor
                        ? recapCardBackgroundColor
                        : "#ffffff",
                    }
              }
            >
              <div className="flex flex-row justify-between items-center w-full">
                <span
                  className="text-xs text-white flex items-center justify-center mt-[5px]"
                  style={{
                    background: dynamicTheme?.palette["primary"]?.main,
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                >
                  {extrasAndInsurance ? "4" : "3"}
                </span>
                <p
                  className="text-lg text-black grow ml-[10px]"
                  style={
                    {
                      color:
                        activeStep > 2
                          ? dynamicTheme?.palette["backgroundColor"]
                              ?.contrastText
                          : dynamicTheme?.palette["backgroundColor"]
                              ?.contrastText,
                    } && activeStep == 2
                      ? { fontWeight: "bold" }
                      : { fontWeight: "normal" }
                  }
                >
                  {translate("Total Rental")}
                </p>
                <button
                  style={{ color: "black", float: "right" }}
                  onClick={toggleExpand4}
                >
                  {isExpanded4 ? <ExpandLess /> : <ExpandMore />}
                </button>
              </div>
              {activeStep > 0 && isExpanded4 ? (
                <div className="flex flex-col text-black">
                  {extrasAndInsurance && (
                    <>
                      <div className="flex justify-between">
                        <span className="pt-1 pb-1">
                          {translate("Totale Noleggio:")}
                        </span>
                        <span className="pt-1 pb-1">
                          {defaultCurrency || "€"}
                          {vehiclePrice?.toFixed(2) || 0}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="pt-1 pb-1">
                          {translate("Totale Coperture:")}
                        </span>
                        <span className="pt-1 pb-1">
                          {defaultCurrency || "€"}
                          {insurancePrice?.toFixed(2) || 0}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="pt-1 pb-1">
                          {translate("Totale extras:")}
                        </span>
                        <span className="pt-1 pb-1">
                          {defaultCurrency || "€"}
                          {onlyExtrasPrice?.toFixed(2) || 0}
                        </span>
                      </div>
                    </>
                  )}
                  <div className="flex justify-between font-bold">
                    <span className="pt-1 pb-1"> {translate("Totale")}</span>
                    <span className="pt-1 pb-1">
                      {defaultCurrency || "€"}
                      {selectedPrice?.toFixed(2) || 0}
                    </span>
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </section>
          </div>
        )}
        {/*-----------------------------------------------------------card for desktop view----------------------------------------------------------------------------------*/}
        {isDesktopScreen && (
          <div
            className={`box-border py-4 flex flex-row hidden md:flex gap-1 sticky ${
              appSettings?.headerAndFooter ? "top-10" : "top-0"
            } z-[10] w-full`}
            style={{
              background: secondPageBackgroundColor
                ? secondPageBackgroundColor
                : "#ffffff",
              borderRadius: appSettings?.recapHeaderBorderRadius
                ? `${appSettings?.recapHeaderBorderRadius}rem`
                : "0px",
            }}
          >
            {cards.map((e, index) => {
              const className = extrasAndInsurance ? "w-1/4" : "w-1/3";
              if (index === 2 && !extrasAndInsurance) {
                return null;
              }
              return (
                <div key={index} className={className}>
                  {e.label}
                </div>
              );
            })}
          </div>
        )}
        {/*-------------------------------------------------------------------------------------------------*/}
        {activeStep == 0 && (
          <>
            {showFilterAtTop && isFilterEnable && isDesktopScreen && (
              <VehicleFiltersAtTop />
            )}
            <div className={"flex flex-row gap-4 md-4"}>
              <main className={getClassName()}>
                {!isLoading ? (
                  <ShowVehicles
                    onVehicleSelect={async (e: StateSelectedVehicleDetails) => {
                      setSelectedVehicle(e.vehicleDetails);
                      if (extrasAndInsurance) {
                        setActiveStep(1);
                        window.history.pushState({ step: 1 }, "Step 1", "");
                      } else {
                        handleStepTwoActions(e.vehicleDetails);
                      }
                      try {
                        setIsInsuranceLoading(true);
                        const insuranceDetails =
                          await BookingApi.getInsuranceDetails(
                            ReservationDetails,
                            e.vehicleDetails.Vehicle?.Code || "",
                            e.vehicleDetails.optionals || []
                          );
                        setInsuranceList(insuranceDetails);
                        setGroupName(
                          e.vehicleDetails.Vehicle?.VehMakeModel?.Name || ""
                        );
                        if (insuranceDetails["Booking Insurance"]) {
                          insuranceDetails["Booking Insurance"].forEach(
                            (item) => {
                              findTextForInsuranceBody(
                                item.Description,
                                e.vehicleDetails.Vehicle?.VehMakeModel?.Name
                              );
                            }
                          );
                        }
                      } catch (error) {
                        console.error(
                          "Error fetching insurance details:",
                          error
                        );
                      } finally {
                        setIsInsuranceLoading(false);
                      }
                      setDiscountValueWithoutVat(
                        e?.vehicleDetails?.Reference?.ID_without_vat
                          ? e?.vehicleDetails?.Reference?.ID_without_vat?.toString()
                          : e?.vehicleDetails?.Reference?.ID?.toString()
                      );
                    }}
                    allVehicles={allVehicles}
                    vehiclesFilter={filterValue}
                    className="animate__animated animate__slideInUp"
                    textColor={
                      dynamicTheme?.palette["secondaryColor"]?.contrastText
                    }
                    reservationSourcePayNow={reservationSourcePayNow}
                    reservationSourcePayLater={reservationSourcePayLater}
                    backgroundColor={
                      dynamicTheme?.palette["backgroundColor"]?.main
                    }
                    backgroundTextColor={
                      dynamicTheme?.palette["secondaryColor"]?.main
                    }
                    errorMsgNoVehicle={errorMsgNoVehicle}
                  />
                ) : (
                  <div
                    className={
                      "flex flex-col justify-center items-center w-full"
                    }
                  >
                    <CircularProgress />
                  </div>
                )}
              </main>
              {isFilterEnable && !showFilterAtTop ? (
                <aside
                  className="w-80 sticky top-0 lg:flex hidden flex-col cursor-pointer bg-[#FFFFFF]"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    marginTop: "1%",
                  }}
                >
                  <VehicleFilters />
                </aside>
              ) : null}
              <Drawer anchor="right" open={open} onClose={handleDrawerToggle}>
                <VehicleFilters />
              </Drawer>
            </div>
          </>
        )}
        <section className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] relative flex flex-col bg-white  box-border font-roboto  text-[#004188] z-12">
          {showInfo && (
            <div className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn">
              <div>
                <Button
                  className="w-1/3 flex justify-end"
                  onClick={() => {
                    toogleVehicleInfoCardShowingStatus();
                  }}
                >
                  close
                </Button>
                <p>This is the info that should be displayed on the card.</p>
              </div>
            </div>
          )}
        </section>
        {activeStep == 1 && (
          <div
            className={`h-full w-full animate__animated animate__slideInUp ${
              isSmallScreen ? "mt-[5%]" : ""
            }`}
          >
            {(!appSettings?.donotShowInsurance &&
              insuranceList["Booking Insurance"]?.length) > 0 ||
            (!appSettings?.donotShowExtras &&
              selectedFields.options != null) ? (
              <div
                className="flex flex-col gap-4 items-center justify-center"
                style={{
                  marginTop: getMarginTop(),
                  marginBottom: "19px",
                }}
              >
                {isInsuranceLoading ? (
                  <div className="flex flex-col justify-center items-center w-full">
                    <CircularProgress />
                  </div>
                ) : (
                  !appSettings?.donotShowInsurance &&
                  insuranceList["Booking Insurance"]?.length > 0 && (
                    <div
                      ref={insuranceRef}
                      className="w-full box-border p-6 rounded-md shadow-md"
                      style={{
                        backgroundColor:
                          dynamicTheme?.palette["backgroundColor"].main,
                      }}
                    >
                      <div style={{ marginBottom: "30px" }}>
                        {appSettings?.showInsurancePreview && includedIns && (
                          <InsurancePreview
                            insurance={includedIns}
                            specification={insuranceList["Booking Insurance"]}
                            updateShowInsurance={updateShowInsurance}
                            previewModal={"includedInsurance"}
                          />
                        )}
                      </div>
                      {showInsurancePreview &&
                      appSettings?.showInsurancePreview &&
                      selectedFields.insurance ? (
                        <InsurancePreview
                          insurance={selectedFields.insurance}
                          specification={insuranceList["Booking Insurance"]}
                          updateShowInsurance={updateShowInsurance}
                          previewModal={"selectedInsurance"}
                        />
                      ) : (
                        <InsuranceCardsLayout
                          selectedInsuranceIndex={insuranceList[
                            "Booking Insurance"
                          ].findIndex(
                            (e) =>
                              e.Description.toLowerCase() ===
                              selectedFields.insurance?.details.toLowerCase()
                          )}
                          onTriggerFindOutMoreUpdate={() =>
                            setIsFindMoreTriggeredInInsComp((prev) => !prev)
                          }
                          onSelected={(e: number) => {
                            const index = e;
                            appSettings?.showInsurancePreview &&
                              setShowInsurancePreview(true);
                            setSelectedFields((prev) => {
                              if (index < 0) {
                                delete prev.insurance;
                              } else {
                                let updatedInsuranceList = insuranceList;
                                if (appSettings?.showInsurancePreview) {
                                  updatedInsuranceList = {
                                    ...insuranceList,
                                    "Booking Insurance": insuranceList[
                                      "Booking Insurance"
                                    ].filter(
                                      (insurance) => insurance !== includedIns
                                    ),
                                  };
                                }
                                prev.insurance = {
                                  details:
                                    updatedInsuranceList["Booking Insurance"][
                                      index
                                    ].Description,
                                  insuranceId:
                                    updatedInsuranceList["Booking Insurance"][
                                      index
                                    ].Id.toString(),
                                  price: parseFloat(
                                    parseFloat(
                                      updatedInsuranceList["Booking Insurance"][
                                        index
                                      ].Amount.toString()
                                    ).toFixed(2)
                                  ),
                                };
                              }
                              return {
                                ...prev,
                              };
                            });
                          }}
                          insurances={
                            insuranceList["Booking Insurance"]?.map((e) => {
                              return {
                                name: e.Description,
                                pricePerDayInUSD: e.Amount.toString(),
                                features: e.Specification,
                                isRecommended: e.isRecommended,
                                bookingText: e.bookingText,
                              };
                            }) || []
                          }
                          secondaryColor={
                            dynamicTheme?.palette["secondaryColor"]?.main
                          }
                          cardBackgroundColor={
                            dynamicTheme?.palette["backgroundColor"]?.main
                          }
                          selectedCardBackgroundColor={
                            dynamicTheme?.palette["ternaryColor"]?.main
                          }
                          cardTextColor={
                            dynamicTheme?.palette["backgroundColor"]
                              ?.contrastText
                          }
                          selectedCardTextColor={
                            dynamicTheme?.palette["ternaryColor"]?.contrastText
                          }
                          fillToCard={appSettings?.fillColorToCard}
                          selectedGroup={
                            selectedVehicle?.Vehicle?.VehMakeModel?.Name
                          }
                          insuranceBody={findInsuranceBody}
                          vehicleGroupName={groupName}
                        />
                      )}
                    </div>
                  )
                )}
                {!appSettings?.donotShowExtras && (
                  <div className="w-full">
                    <div ref={selectVehicleExtrasRef}>
                      <SelectVehicleExtras
                        selectedInsurance={selectedFields?.insurance}
                        values={selectedFields.options || {}}
                        onChange={(e: any) => {
                          setSelectedFields((prev) => {
                            return {
                              ...prev,
                              // abc: 1, implement the logic for automatically adding age related extras
                              options: e,
                            };
                          });
                        }}
                        insuranceList={insuranceList}
                        options={
                          selectedVehicle?.optionals?.map((e) => {
                            return {
                              ...e,
                              iconUrl: e.Equipment.optionalImage || "#",
                              maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                              optionName: e.Equipment?.EquipType || "",
                              pricePerDayInUSD: (
                                e.Charge.Amount || 0
                              ).toString(),
                              details: e.Equipment?.Description || "",
                              donotShowOptional:
                                e.Equipment?.donotShowOptional == true
                                  ? true
                                  : false,
                              isDailyPrice:
                                e?.Equipment?.isDailyPrice == true
                                  ? true
                                  : false,
                            };
                          }) || []
                        }
                        cardBackgroundColor={
                          appSettings?.fillColorToCard
                            ? dynamicTheme?.palette["backgroundColor"]?.main
                            : dynamicTheme?.palette["secondaryColor"]?.main
                        }
                        selectedCardBackgroundColor={
                          dynamicTheme?.palette["primary"]?.main
                        }
                        cardTextColor={
                          appSettings?.fillColorToCard
                            ? dynamicTheme?.palette["backgroundColor"]
                                ?.contrastText
                            : dynamicTheme?.palette["secondaryColor"]
                                ?.contrastText
                        }
                        selectedCardTextColor={
                          dynamicTheme?.palette["primary"]?.contrastText
                        }
                        secondaryColor={
                          dynamicTheme?.palette["secondaryColor"]?.main
                        }
                        fillToCard={appSettings?.fillColorToCard}
                      />
                    </div>
                  </div>
                )}
                <AppButton
                  className="mb-2"
                  // color="primary"
                  style={{
                    background: appSettings?.continueButtonPrimaryColor
                      ? dynamicTheme?.palette["primary"]?.main
                      : "#cccccc",
                    height: isSmallScreen ? "2rem" : "3rem",
                    width: isSmallScreen ? "6rem" : "8rem",
                    padding: isSmallScreen ? "8px 0px" : "8px 0px",
                  }}
                  onClick={() => {
                    scrollToTop();
                    window.scrollTo(0, 0);
                    if (selectedVehicle) {
                      dispatch(
                        BOOKING_ACTIONS.setSelectedVehicleDetails({
                          paymentDetails: {
                            addedExtraOptions: selectedFields?.options,
                            isDiscounted:
                              searchParams.get("reservationSourcePayLater") ===
                                "true" ||
                              (userId != null &&
                                userId != "null" &&
                                userId != "")
                                ? 0
                                : (parseFloat(
                                    (selectedVehicle?.Reference?.ID &&
                                    selectedVehicle?.Reference?.ID > 0
                                      ? (
                                          selectedVehicle?.Reference
                                            ?.originalAmount -
                                          selectedVehicle?.Reference.ID
                                        ).toString()
                                      : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) ||
                                      "0"
                                  ) *
                                    parseFloat(
                                      selectedVehicle?.Vehicle?.payNowDis
                                    )) /
                                    100 || 0,
                            price: totalPrice,
                            selectedInsurance: selectedFields?.insurance,
                            type: "",
                            isPayLaterFee: false,
                            payLaterFeeAmt: "",
                            extrasPrice: extrasPrice,
                            extrasIncluded: true,
                          },
                          vehicleDetails: selectedVehicle,
                        })
                      );
                      setTimeout(() => {
                        setActiveStep(2);
                        window.history.pushState({ step: 2 }, "Step 2", "");
                      }, 200);
                    }
                    setSelectedPrice(totalPrice);
                  }}
                >
                  <span style={{ fontFamily: fontFamilyStyle }}>
                    {translate("Continue")}
                  </span>
                </AppButton>
              </div>
            ) : (
              <p className="flex flex-col gap-4 items-center justify-center text-[#000000]">
                No Insurance/Extras Available
                <AppButton
                  className="w-fit mb-2"
                  color="secondaryColor"
                  onClick={() => {
                    scrollToTop();
                    window.scrollTo(0, 0);
                    if (selectedVehicle) {
                      dispatch(
                        BOOKING_ACTIONS.setSelectedVehicleDetails({
                          paymentDetails: {
                            addedExtraOptions: selectedFields?.options,
                            isDiscounted:
                              searchParams.get("reservationSourcePayLater") ===
                                "true" ||
                              (userId != null &&
                                userId != "null" &&
                                userId != "")
                                ? 0
                                : (parseFloat(
                                    (selectedVehicle?.Reference?.ID &&
                                    selectedVehicle?.Reference?.ID > 0
                                      ? (
                                          selectedVehicle?.Reference
                                            ?.originalAmount -
                                          selectedVehicle?.Reference.ID
                                        ).toString()
                                      : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) ||
                                      "0"
                                  ) *
                                    parseFloat(
                                      selectedVehicle?.Vehicle?.payNowDis
                                    )) /
                                    100 || 0,
                            price: totalPrice,
                            selectedInsurance: selectedFields?.insurance,
                            isPayLaterFee: false,
                            payLaterFeeAmt: "",
                            type: "",
                            extrasPrice: extrasPrice,
                            extrasIncluded: true,
                          },
                          vehicleDetails: selectedVehicle,
                        })
                      );
                      setTimeout(() => {
                        setActiveStep(2);
                        window.history.pushState({ step: 2 }, "Step 2", "");
                      }, 200);
                    }
                    setSelectedPrice(totalPrice);
                  }}
                >
                  Continue
                </AppButton>
              </p>
            )}
          </div>
        )}
        {activeStep === 2 && (
          <div
            className="h-full w-full animate__animated animate__slideInUp"
            style={{ marginTop: payMarginTop() }}
          >
            <PaymentDetailsPage
              reservationSourcePayNow={reservationSourcePayNow}
              reservationSourcePayLater={reservationSourcePayLater}
              updateVehiclePrice={updateVehiclePrice}
              updateSelectedPrice={updateSelectedPrice}
              allPayLaterMethods={allPayLaterMethods}
              allPayNowMethods={allPayNowMethods}
              updateActiveStep={updateActiveStep}
              isAirport={isAirport}
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export { ChooseVehiclePage };
